import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

/* MUI */
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Delete, Edit, PendingActions, Person, WorkspacePremium } from '@mui/icons-material'
import axios from 'axios'

export default function DashboardController({apiglobal, snackbar}){
    const navi = useNavigate()
    const [working, setWorking] = useState('')

    const [dash, setDash] = useState([])
    const [newstudents, setNewstudents] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard', '')
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === true && setDash(data.data)
            setNewstudents(data.newstudents)
        })
    }, [])

    useEffect(() => {
        getPosts()
    }, [])
    const [posts, setPosts] = useState([])
    const getPosts = () => {
        var formData = new FormData()
        formData.append('get-posts', '')
        axios.post(apiglobal, formData)
        .then(function(res){
            res.data.status === false ? setPosts(res.data) : setPosts(res.data.data)
        })
    }

    const [openpostdialog, setOpenpostdialog] = useState(false)
    const [postid, setPostid] = useState('')
    const [post, setPost] = useState('')
    const createpost = () => {
        setWorking('creatingpost')
        var formData = new FormData()
        formData.append('create-post', postid)
        formData.append('post', post)
        axios.post(apiglobal, formData)
        .then(function(res){
            setWorking('')
            res.data.status === false ? snackbar(res.data.msg) : snackbar(res.data.msg)
            res.data.status === true && setOpenpostdialog(false); getPosts(); setPost('')
        })
    }

    const getPostDetail = (postid) => {
        setOpenpostdialog(true); setPostid(postid)
        var formData = new FormData()
        formData.append('get-post-detail', postid)
        axios.post(apiglobal, formData)
        .then(function(res){
            res.data.status === true && setPost(res.data.data.post)
        })
    }

    const deletePost = (postid) => {
        setWorking(postid)
        var formData = new FormData()
        formData.append('delete-post', postid)
        axios.post(apiglobal, formData)
        .then(function(res){
            setWorking('')
            res.data.status === true && snackbar(res.data.msg)
            res.data.status === true && getPosts()
        })
    }

    return(
        <Box className='content' >
        <Container >
            <Grid container spacing={2} mt={3} >
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <Person sx={{fontSize: 70}} color='warning' />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >STUDENTS</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.students}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <WorkspacePremium sx={{fontSize: 70}} color='primary' />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >ACTIVE</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.active_students}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <PendingActions sx={{fontSize: 70}} color='error' />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >PENDING</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.pending_students}</Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={8}>
                    <Box padding={2} component={Paper} height={350} overflow='auto' >
                        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={1} >
                            <Typography fontSize={14} color='gray'>7 days admission record</Typography>
                        </Stack><Divider/>
                        { newstudents.status === false ? <Alert severity='warning' sx={{mt: 3}} >{newstudents.msg}</Alert> :
                        <TableContainer>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Father</TableCell>
                                    <TableCell align="left">Gender</TableCell>
                                    <TableCell align="right">Join date</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {newstudents.map((row, i) => (
                                    <TableRow sx={{cursor: 'pointer'}}
                                    key={i} onClick={() => navi(`/controller/student-upload/${row.studentid}`)} >
                                    <TableCell component="th" scope="row">
                                        {i+1}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.guardian}</TableCell>
                                    <TableCell align="left">{row.gender}</TableCell>
                                    <TableCell align="right">{row.joindate}</TableCell>
                                    <TableCell align="right" sx={{color: row.status === 'active' ? 'green' : 'orange', fontWeight: 'bold' }} >{row.status}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer> }
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box padding={2} component={Paper} height={350} overflow='auto' >
                        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={1} >
                            <Typography fontSize={14} color='gray'>Public posts</Typography>
                            <IconButton sx={{bgcolor: '#F5F5F5'}} onClick={() => setOpenpostdialog(true)} ><Edit/></IconButton>
                        </Stack><Divider/>
                        
                        <Box mt={1} >
                            { posts.status === false ? <Alert severity='warning'>{posts.msg}</Alert> :
                            posts.map((post, i) =>
                            <Box mb={1} padding={1} sx={{background: '#fff3e0'}} borderRadius={1} key={i} >
                                <Stack direction='row' alignItems='center' justifyContent='space-between' mb={1} >
                                    <Typography fontSize={14} color='gray'>#{i+1}</Typography>
                                    <Box>
                                        <IconButton size='small' disabled={working === post.postid ? true : false} onDoubleClick={() => deletePost(post.postid)} >{working === post.postid ? <CircularProgress color='inherit' size={24} /> : <Delete/>}</IconButton>
                                        <IconButton size='small' onClick={() => getPostDetail(post.postid)} ><Edit/></IconButton>
                                    </Box>
                                </Stack><Divider/>
                                <Typography fontSize={14} mt={1} >{post.post}</Typography>
                                <Typography fontSize={12} mt={1} >Post date: {new Date(post.post_date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: '2-digit'})}</Typography>
                            </Box>)}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        <Dialog open={openpostdialog} fullWidth maxWidth='sm' onClose={() => setOpenpostdialog(false)} >
            <DialogTitle>Public post</DialogTitle><Divider/>
            <DialogContent>
                <TextField fullWidth variant='filled' multiline rows={6} placeholder='Enter your post detail here...' value={post} onChange={(event) => setPost(event.target.value)} />
            </DialogContent><Divider/>
            <DialogActions>
                <Button variant='outlined' onClick={() => setOpenpostdialog(false)} >Close</Button>
                <Button variant='contained' onClick={() => createpost()} disabled={working === 'creatingpost' ? true : false} >{working === 'creatingpost' ? <CircularProgress color='inherit' size={24} /> : 'Post'}</Button>
            </DialogActions>
        </Dialog>
        </Box>
    );
}