import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Avatar, Box, Button, Container, Divider, FormControl, Grid, InputAdornment, InputLabel, LinearProgress, OutlinedInput, Paper, Stack, Toolbar, Typography } from '@mui/material'

import Footer from "./footer";
import { Check, CheckBox } from "@mui/icons-material";

export default function Verification({apipublic, snackbar}){

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const [vdata, setVerification] = useState([])
    const [typing, setTyping] = useState([])
    const [cert, setCert] = useState([])
    const [working, setWorking] = useState('')
    const [enrollment, setEnrollment] = useState('')
    const verify = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('verify-certificate', enrollment)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.status == false){
                snackbar(data.message)
            } else {
                setVerification(data.data); setCert(data.cert); setTyping(data.typing)
            }
        })
    }

    return(
        <Box>
            <Container>
                <Grid container mt={20} mb={30} >
                    <Grid item xs={12} md={7} mx='auto'>
                        <Box>
                            <Typography fontSize={40} color='orange' align="center" fontWeight='bold' mb={5} fontFamily='serif' >Verify your Entity!</Typography>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel htmlFor="certificate">Enter your certificate number</InputLabel>
                                <OutlinedInput
                                    id="certificate"
                                    type='text'
                                    onChange={(event) => setEnrollment(event.target.value)}
                                    onKeyDown={(event) => event.key === 'Enter' && verify()}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <Button onClick={() => verify()} >Verify</Button>
                                    </InputAdornment>
                                    }
                                    label="Enter your certificate number"
                                />
                            </FormControl>
                        </Box>
                        
                        <Box mt={3} >
                            {working ?  <LinearProgress color="primary" /> :
                            vdata.length !== 0 && <>
                            <Grid container component={Paper} pt={3} pb={3}>
                                <Grid item xs={12}>
                                    <Toolbar>
                                        <CheckBox color="success" fontSize="large" />
                                        <Typography fontSize={20} fontFamily='initial' fontWeight='bold' className="text-gradient1" letterSpacing={1} >Certified</Typography>
                                    </Toolbar>
                                    <Typography fontSize={16} fontWeight='bold' className="text-gradient1" ml={3.5} >Certificate truly issued by VIIT Computer Education</Typography>
                                    <Typography ml={3.5} fontSize={14} >From the study center: {vdata.fr_name}</Typography>
                                    <Typography ml={3.5} fontSize={14} >Center code: {vdata.fr_code}</Typography>
                                    <Typography ml={3.5} fontSize={14} >Center address: {vdata.fr_address}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container component={Paper} pt={3} pb={3} mt={2} >
                                <Grid item xs={12} md={12} >
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Certificate number</Typography>
                                        <Typography fontSize={16} fontWeight='bold' color='darkblue' >{vdata.certificate_no}</Typography>
                                    </Toolbar><Divider/>
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Name</Typography>
                                        <Typography fontSize={16} fontWeight='bold' color='orange' >{vdata.name}</Typography>
                                    </Toolbar><Divider/>
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Father's name</Typography>
                                        <Typography fontSize={16} fontWeight='bold' color='orange' >{vdata.guardian}</Typography>
                                    </Toolbar><Divider/>
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Issue date</Typography>
                                        <Typography fontSize={16} fontWeight='bold' color='green' >{vdata.issued_on}</Typography>
                                    </Toolbar>
                                </Grid>
                            </Grid>
                            { typing === false ?
                            <Grid container component={Paper} mt={2}>
                                <Grid item xs={12} md={12}>
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Selected course</Typography>
                                        <Box>
                                            <Typography fontSize={16} fontWeight='bold' align="right" >{vdata.c_name}</Typography>
                                            <Typography fontSize={14} align="right" >{vdata.c_title} - {vdata.c_duration} Months</Typography>
                                        </Box>
                                    </Toolbar>
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Enrollment no.</Typography>
                                        <Typography fontSize={16} fontWeight='bold' align="right" color='darkblue' >{vdata.enrollment_no}</Typography>
                                    </Toolbar>
                                </Grid>
                            </Grid> :
                            
                            <Grid container component={Paper} mt={2}>
                                <Grid item xs={12} md={12}>
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Selected course</Typography>
                                        <Box>
                                            <Typography fontSize={16} fontWeight='bold' align="right" >{vdata.c_name}</Typography>
                                            <Typography fontSize={14} align="right" >{vdata.c_title} - {vdata.c_duration} Months</Typography>
                                        </Box>
                                    </Toolbar><Divider/>
                                    <Toolbar>
                                        <Typography fontSize={14} color='gray' sx={{flexGrow: 1}} >Enrollment no.</Typography>
                                        <Typography fontSize={16} fontWeight='bold' align="right" color='darkblue' >{vdata.enrollment_no}</Typography>
                                    </Toolbar>
                                </Grid>
                            </Grid> } </> }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        <Footer/> </Box>
    );
}