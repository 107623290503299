import React, { useState } from "react";
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from "@emotion/react"
import Cookies from 'js-cookie'

// MUI
import { Snackbar, createTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

import Debug from "./debug";

// PAGES
import Header from './pages/header'
import Home from './pages/home'
import Verification from "./pages/verification"
import Contact from "./pages/contact";
import CoursesList from './pages/courses'
import GalleryPublic from './pages/gallery'

// Controller
import AdminLogin from './login/admin/index'
import AdminReset from './login/admin/reset'
import FranchiseLogin from "./login/franchise/index";
import FranchiseReset from './login//franchise/reset'
import SidebarController from "./controller/sidebar";
import DashboardController from './controller/dashboard'
import DashboardAdmin from './controller/dashboard-admin'
import Franchises from "./controller/franchise";
import FranchiseUpload from "./controller/franchise-upload";
import Courses from "./controller/course";
import CourseUpload from "./controller/course-upload";
import CourseSubject from './controller/course-subject'
import CourseMarks from './controller/course-marks'
import Students from "./controller/student";
import StudentUpload from './controller/student-upload'
import StudentInfo from './controller/student-info'
import StudentMarks from "./controller/student-marks";
import AdmissionForm from "./controller/admission-form";
import Gallery from "./controller/gallery";

// PUBLIC
import ComputerCertificate from './public/certificate'
import TypingCertificate from './public/typing-certificate'
import ComputerMarksheet from './public/marksheet'

function App() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1920
      },
    },
    palette: {
      primary: {
        light: '#379BFF',
        main: '#007FFF',
        dark: '#004080',
        contrastText: '#FFFFFF',
      },
      secondary: {
        light: '#005BB6',
        main: '#004080',
        dark: '#002952',
        contrastText: '#FFFFFF',
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    }
  })

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const handlesnackbar = (data) => {
    setMessage(data); setOpen(true)
  }

  const auth = Cookies.get('authuser')
  const fullscreen = useMediaQuery(useTheme().breakpoints.down('md'));

  // ASSETS
  const assetfranchise = '/viitassets/franchise/'
  const assetstudent = '/viitassets/student/'
  const gallery = '/viitassets/gallery/'

  // APIS
  const apilogin = '/viitassets/api/login/index'
  const apiadmin = '/viitassets/api/admin/index'
  const apiglobal = '/viitassets/api/global/index'
  const apipublic = '/viitassets/api/public/index'


  return (
    <ThemeProvider theme={theme} >
      <Snackbar open={open} message={message} onClose={() => setOpen(false)} anchorOrigin={{vertical: 'top', horizontal: 'right'}} />
      <Routes>
        <Route path="/debug" element={<Debug />} />
        <Route path="/login-admin" element={<AdminLogin apiadminlogin={apilogin} snackbar={handlesnackbar} />} />
        <Route path="/reset-admin" element={<AdminReset apilogin={apilogin} snackbar={handlesnackbar} />} />

        <Route path="/login-franchise" element={<FranchiseLogin apilogin={apilogin} snackbar={handlesnackbar} />} />
        <Route path="/reset-franchise" element={<FranchiseReset apilogin = {apilogin} snackbar={handlesnackbar} />} />
        
        <Route path="/" element={<Header />}>
          <Route path="/" element={<Home apipublic={apipublic} gallery={gallery} fullscreen={fullscreen} />} />
          <Route path="/verification" element={<Verification apipublic={apipublic} snackbar={handlesnackbar} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/courses" element={<CoursesList />} />
          <Route path="/gallery" element={<GalleryPublic apipublic={apipublic} gallery={gallery} />} />
        </Route>

        <Route path="/public/computer-certificate/:studentid/:courseid" element={<ComputerCertificate apipublic={apipublic} />} />
        <Route path="/public/computer-marksheet/:studentid/:courseid" element={<ComputerMarksheet apipublic={apipublic} />} />
        <Route path="/public/typing-certificate/:studentid/:courseid" element={<TypingCertificate apipublic={apipublic} />} />
        <Route path="/admission-form/:studentid/:courseid" element={<AdmissionForm apiglobal={apiglobal} />} />

        <Route path='/controller' element={<SidebarController apiglobal={apiglobal} />}>
          <Route path="dashboard" element={<DashboardController apiglobal={apiglobal} snackbar={handlesnackbar} />} />
          <Route path="dashboard-admin" element={<DashboardAdmin apiglobal={apiglobal} apiadmin={apiadmin} />} />
          <Route path="franchises/*" element={<Franchises snackbar={handlesnackbar} apiadmin={apiadmin} assetfranchise={assetfranchise} />} />
          <Route path="franchise-upload/:franchiseid" element={<FranchiseUpload snackbar={handlesnackbar} apiadmin={apiadmin} />} />
          <Route path="courses" element={<Courses apiadmin={apiadmin} />} />
          <Route path="course-upload/:courseid" element={<CourseUpload apiadmin={apiadmin} snackbar={handlesnackbar} />} />
          <Route path="course-subject/:courseid" element={<CourseSubject apiadmin={apiadmin} snackbar={handlesnackbar} />} />
          <Route path="course-marks/:courseid" element={<CourseMarks apiadmin={apiadmin} snackbar={handlesnackbar} />} />
          <Route path="students" element={<Students auth={auth} apiglobal={apiglobal} />} />
          <Route path="student-upload/:studentid" element={<StudentUpload auth={auth} apiglobal={apiglobal} snackbar={handlesnackbar} />} />
          <Route path="student-info/:studentid" element={<StudentInfo apiadmin={apiadmin} apiglobal={apiglobal} snackbar={handlesnackbar} auth={auth} assetstudent={assetstudent} fullscreen={fullscreen} />} />
          <Route path="student-marks/:studentid/:courseid" element={<StudentMarks apiglobal={apiglobal} snackbar={handlesnackbar} auth={auth} />} />
          <Route path="gallery" element={<Gallery apiadmin={apiadmin} gallery={gallery} snackbar={handlesnackbar} />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
