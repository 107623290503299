import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

// MUI
import { Box, Button, CircularProgress, Container, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function FranchiseReset({apilogin, snackbar}){
    let navi = useNavigate()
    const auth = Cookies.get('authuser')
    useEffect(() => {
        if(auth === 'franchise'){
            navi('/controller/dashboard', {replace: true})
        }
    }, [auth])

    const [otpsent, setOtpsent] = useState(false)
    const [email, setEmail] = useState('')
    const [newpassword, setNewpassword] = useState('')
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState(false)
    const sendOtp = () => {
        setLoading(true)
        var formData = new FormData()
        formData.append('send-otp-franchise', email)
        fetch(apilogin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.status === false){
                snackbar(data.message)
            } else {
                setOtpsent(data.status)
            }
        })
    }

    const reset = () => {
        setLoading(true)
        var formData = new FormData()
        formData.append('reset-password-franchise', email)
        formData.append('newpassword', newpassword)
        formData.append('otp', otp)
        fetch(apilogin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.status === false){
                snackbar(data.message)
            } else {
                navi('/controller/dashboard', {replace: true})
            }
        })
    }

    return(
        <Box>
            <Container>
                <Grid container>
                    <Grid item xs={11} md={5} mx='auto' sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}} >
                        <Paper sx={{width: '100%', padding: 3, pb: 5}} >
                            <Box mb={5} >
                                <Typography fontSize={30} fontWeight='bold' letterSpacing={1} color='primary.main' align="center" mb={2} >RESET PASSWORD</Typography>
                                <Divider/>
                            </Box>
                            
                            <TextField fullWidth variant="standard" type="email" label='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                            {otpsent ?<>
                            <TextField fullWidth variant="standard" type="text" label='New Password' sx={{mt: 3}} value={newpassword} onChange={(event) => setNewpassword(event.target.value)} />
                            <TextField fullWidth variant="standard" type="number" label='OTP' sx={{mt: 3}} value={otp} onChange={(event) =>  setOtp(event.target.value)} /></> : null }
                            <Box mt={3} textAlign='center' >
                                { otpsent ? 
                                <Button variant="contained" color="primary" sx={{width: '100%'}} onClick={() => reset()} disabled={loading} >{loading ? <CircularProgress color="inherit" size={24} /> : 'Reset'}</Button> :
                                <Button variant="contained" color="primary" sx={{width: '100%'}} onClick={() => sendOtp()} disabled={loading} >{loading ? <CircularProgress color="inherit" size={24} /> : 'Verify'}</Button> }
                                <Button variant="outlined" color="primary" sx={{width: '100%', mt: 1}} onClick={() => navi(-1)} >Go back</Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}