import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'


/* MUI */
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Drawer, Typography, Toolbar, AppBar, Divider, IconButton } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GroupIcon from '@mui/icons-material/Group';
import BookIcon from '@mui/icons-material/Book';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Menu, PowerSettingsNew } from '@mui/icons-material';

export default function Debug(){
    

    const [frname, setFrname] = useState('Tezpur University');

    const [open, setOpen] = useState(false)

    const links = [
      {name: 'Dashboard', link: `/controller/`, icon: <DashboardIcon/>},
      {name: 'Franchise', link: '/controller/franchises', icon: <AccountTreeIcon/>},
      {name: 'Course', link: '/controller/courses', icon: <BookIcon/>},
      {name: 'Student', link: '/controller/students', icon: <GroupIcon/>},
      {name: 'Gallery', link: '/controller/gallery', icon: <InsertPhotoIcon/>}
  ]

    return(
        <Box component='nav' sx={{marginBottom: 10}} >
            <Drawer variant='permanent' PaperProps={{style: {width: '256px', background: 'secondary.main'}}} sx={{display: {xs: 'none', md: 'block'}}} >
                <Box pt={2.1} pb={2.1} sx={{backgroundColor: 'primary.main'}} >
                    <Typography fontSize={20} letterSpacing={2} fontFamily='initial' color='white' fontWeight='bold' noWrap align='center' textTransform='uppercase' >{frname}</Typography>
                </Box>
                <Divider/>
                <List>
                    {links.map((data, i) =>
                    <NavLink key={i} to={data.link} activestyle={{color: 'red'}} style={{color: 'black'}} >
                        <ListItemButton>
                            <ListItem>
                                    <ListItemIcon>{data.icon}</ListItemIcon>
                                    <ListItemText>{data.name}</ListItemText>
                            </ListItem>
                        </ListItemButton>
                    </NavLink>)}
                </List>
            </Drawer>
            <Drawer open={open} anchor='bottom' onClose={() => setOpen(false)} PaperProps={{style: {borderTopRightRadius: 25, borderTopLeftRadius: 25}}} >
                <List>
                    {links.map((data, i) =>
                    <NavLink key={i} to={data.link} style={{color: 'black'}} >
                        <ListItem>
                            <ListItemButton>
                                <ListItemIcon>{data.icon}</ListItemIcon>
                                <ListItemText>{data.name}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </NavLink>)}
                </List>
            </Drawer>
            <AppBar color='primary' position='fixed' >
                <Toolbar>
                    <Typography noWrap fontSize={20} fontWeight='bold' letterSpacing={2} color='inherit' fontFamily='serif' sx={{flexGrow: 1, display: {xs: 'block', md: 'none'}}} >{frname}</Typography>
                    <Typography sx={{flexGrow: 1, xs: 'none', md: 'block'}}></Typography>
                    <IconButton color='inherit' ><PowerSettingsNew/></IconButton>
                    <Box sx={{display: {xs: 'block', md: 'none'}}} >
                        <IconButton color='inherit' edge='end' ><Menu/></IconButton>
                    </Box>
                </Toolbar>
            </AppBar>   
        </Box>
    );
}