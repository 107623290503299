import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

// MUI
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material'
import { Login, Shortcut } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu';

export default function Header(){
    let navi= useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const links = [
        {name: 'Home', link: '/', icon: <Shortcut/>},
        {name: 'Gallery', link: '/gallery', icon: <Shortcut/>},
        {name: 'Verification', link: '/verification', icon: <Shortcut/>},
        {name: 'Courses', link: '/courses', icon: <Shortcut/>},
        {name: 'Contact', link: '/contact', icon: <Shortcut/>}
    ]
    const [open, setOpen] = useState(false)

    const goto = (link) => {
        setOpen(false)
        navi(link)
    }

    return(
        <>
            <AppBar color="primary" position="sticky" elevation={1} >
                <Toolbar>
                    <NavLink style={{flexGrow: 1}} to='/' ><img src="/logo.webp" style={{width: 150}} /></NavLink>
                    
                    <Box sx={{display: {xs: 'none', md: 'block'}}} >
                        <Stack spacing={2} direction='row' >
                            { links.map((row, i) =>
                            <Typography noWrap key={i} ><NavLink style={{color: 'white'}} to={row.link} >{row.name}</NavLink></Typography>) }
                            <Typography noWrap onClick={(event) => handleClick(event)} sx={{cursor: 'pointer'}} >Login</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{display: {xs: 'block', md: 'none'}}} >
                        <IconButton color="inherit" onClick={() => setOpen(true)} ><MenuIcon/></IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom" >
                <Typography ml={2} mt={2} mb={1.5} fontSize={20} letterSpacing={1} fontWeight='bold' color='primary.main' >VIIT COMPUTER EDUCATION</Typography><Divider/>
                <List>
                    { links.map((row, i) =>
                    <ListItem key={i} onClick={() => goto(row.link)} sx={{cursor: 'pointer'}} >
                        <ListItemIcon>{row.icon}</ListItemIcon>
                        <ListItemText>{row.name}</ListItemText>
                    </ListItem>) }
                    <ListItem onClick={(event) => handleClick(event)} sx={{cursor: 'pointer'}} >
                        <ListItemIcon><Login/></ListItemIcon>
                        <ListItemText>Login</ListItemText>
                    </ListItem>
                </List>
            </Drawer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)} >
                <MenuItem onClick={() => navi('/login-franchise')} >Franchise</MenuItem>
                <MenuItem onClick={() => navi('/login-admin')} >Admin</MenuItem>
            </Menu><Outlet/>
        </>
    );
}