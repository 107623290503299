import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Alert, Box, Button, CircularProgress, Container, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'

export default function CourseSubject({apiadmin, snackbar}){
    let navi = useNavigate()
    const { courseid } = useParams()

    const [subject, setSubject] = useState('')

    useEffect(() => {
        getSubjects()
    }, [])

    const [subjects, setSubjects] = useState([])
    const getSubjects = () => {
        var formData = new FormData()
        formData.append('get-subjects', courseid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status == false){
                setSubjects(data)
            } else {
                setSubjects(data.data)
            }
        })
    }

    const [uploading, setUploading] = useState(false)
    const [subjectid, setSubjectid] = useState('')
    const upload = () => {
        setUploading(true)
        var formData = new FormData()
        formData.append('add-subject', subjectid)
        formData.append('courseid', courseid); formData.append('subject', subject)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.status == false){
                snackbar(data.message)
            } else {
                snackbar(data.message); getSubjects(); setSubject(''); setSubjectid('')
            }
        })
    }

    const subjectDetail = (subjectid) => {
        window.scroll(0, 0)
        setSubjectid(subjectid)
        var formData = new FormData()
        formData.append('get-subject-detail', subjectid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setDeleting(false)
            if(data.status == false){
                snackbar(data.message)
            } else {
                setSubject(data.data.s_subject)
            }
        })

    }

    const [deleting, setDeleting] = useState(false)
    const deleteSubject = (subjectid) => {
        setDeleting(true)
        var formData = new FormData()
        formData.append('delete-subject', subjectid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setDeleting(false)
            if(data.status == false){
                snackbar(data.message)
            } else {
                snackbar(data.message); getSubjects()
            }
        })
    }

    return(
        <Box className='content' >
            <Container>
                <Back text='Franchise upload'/>

                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} >
                    <TextField fullWidth variant="outlined" label='Subject*' type="text" value={subject} onChange={(event) => setSubject(event.target.value)} />
                </Stack>
                <Stack textAlign='right' spacing={1} direction='row' justifyContent='end' mt={2} >
                    <Button variant='outlined' onClick={() => navi(-1)} >Back</Button>
                    <Button variant="contained" sx={{width: '100%'}} onClick={() => upload()} disabled={uploading} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                </Stack>

                <Box mt={3} >
                    { subjects.status === false ? <Alert severity="warning">{subjects.message}</Alert> :
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Subject</TableCell>
                                    <TableCell align="right">Delete</TableCell>
                                    <TableCell align="right">Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { subjects.map((data, i) =>
                                <TableRow key={i} >
                                    <TableCell component="th" scope="row">{i+1}</TableCell>
                                    <TableCell align="left">{data.s_subject}</TableCell>
                                    <TableCell align="right"><IconButton onDoubleClick={() => deleteSubject(data.subjectid)} >{deleting ? <CircularProgress color="inherit" size={24} /> : <Delete/>}</IconButton></TableCell>
                                    <TableCell align="right"><IconButton onClick={() => subjectDetail(data.subjectid)} ><Edit/></IconButton></TableCell>
                                </TableRow> ) }
                            </TableBody>
                        </Table>
                    </TableContainer> }
                </Box>
            </Container>
        </Box>
    );
}