import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import PublicHeader from './header'

// MUI
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function ComputerMarksheet({apipublic}){
    const { studentid } = useParams()
    const { courseid } = useParams()
    const area = useRef()

    const [cert, setCert] = useState([])
    const [info, setInfo] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('get-issued-certificate', studentid)
        formData.append('courseid', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                setCert(data.cert); setInfo(data.data)
            }
        })
    }, [])

    const [subjects, setSubjects] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('get-subject', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                setSubjects(data.data)
            }
        })
    }, [])

    const [marks, setMarks] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('get-subject-marks', studentid)
        formData.append('courseid', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                setMarks(data.data)
            }
        })
    }, [])

    const grade = Number(Number(cert.obtained_marks)*100/Number(cert.s_fmark))

    return(
        <><PublicHeader text='Marksheet' printarea={area} isprint={true} /> <Container>
            <Grid container mt={10} >
                <Grid item xs={12} md={12} mx='auto' overflow='scroll' >
                    <Box ref={area} sx={{position: 'relative', width: '208mm', height: '295mm', mx:'auto'}} >
                        <img src="/images/marksheet.webp" alt="Computer certificate" style={{width: '208mm', height: '295mm'}} />
                        
                        <Typography sx={{position: 'absolute', top: '9mm', left: '158mm'}} fontSize={12} fontWeight='bold' fontFamily='monospace' color='darkred' >{info.certificate_no}</Typography>
                        
                        <Typography sx={{position: 'absolute', top: '84.8mm', left: '60mm', textTransform: 'uppercase'}} fontSize={18} fontWeight='bold' >{info.name}</Typography>
                        <Typography sx={{position: 'absolute', top: '92.8mm', left: '60mm', textTransform: 'uppercase'}} fontSize={18} fontWeight='bold' >{info.guardian}</Typography>
                        <Typography sx={{position: 'absolute', top: '100.8mm', left: '60mm', textTransform: 'uppercase'}} fontSize={18} fontWeight='bold' >{info.enrollment_no}</Typography>
                        <Typography sx={{position: 'absolute', top: '109.2mm', left: '60mm', textTransform: 'uppercase'}} fontSize={14} fontWeight='bold' >{info.c_name}({info.c_title})</Typography>
                        <Typography sx={{position: 'absolute', top: '116mm', left: '60mm'}} fontSize={18} fontWeight='bold' >{info.c_duration} Months</Typography>
                        <Typography sx={{position: 'absolute', top: '126mm', left: '60mm'}} fontSize={18} fontWeight='bold' >{new Date(info.exam_date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', 'year': 'numeric'})}</Typography>

                        <Box sx={{position: 'absolute', top: '149mm', left: '14mm'}} >
                            { subjects.map((data, i) =>
                            <Stack direction='row' alignItems='center' key={i} mt={0.2} >
                                <Typography fontSize={14} >M{i+1}: {data.s_subject}</Typography>
                            </Stack>) }
                        </Box>
                        <Stack direction='row' sx={{position: 'absolute', top: '198.5mm', left: '12mm'}} >
                            <Box sx={{width: '47mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >THEORY</Typography></Box>
                            <Box sx={{width: '29mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{cert.thmarks}</Typography></Box>
                            <Box sx={{width: '29mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{cert.pass_thmarks}</Typography></Box>
                            <Box sx={{width: '37mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{cert.othmarks}</Typography></Box>
                            <Box sx={{width: '37mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" ></Typography></Box>
                        </Stack>
                        <Stack direction='row' mt={4} sx={{position: 'absolute', top: '198.5mm', left: '12mm'}} >
                            <Box sx={{width: '47mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >PRACTICAL</Typography></Box>
                            <Box sx={{width: '29mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{cert.prmarks}</Typography></Box>
                            <Box sx={{width: '29mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{cert.pass_prmarks}</Typography></Box>
                            <Box sx={{width: '37mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{cert.oprmarks}</Typography></Box>
                            <Box sx={{width: '37mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" ></Typography></Box>
                        </Stack>
                        <Stack direction='row' mt={11} sx={{position: 'absolute', top: '198.5mm', left: '12mm'}} >
                            <Box sx={{width: '47mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >TOTAL</Typography></Box>
                            <Box sx={{width: '29mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{Number(cert.prmarks)+Number(cert.thmarks)}</Typography></Box>
                            <Box sx={{width: '29mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{90}</Typography></Box>
                            <Box sx={{width: '37mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{Number(cert.othmarks)+Number(cert.oprmarks)}</Typography></Box>
                            <Box sx={{width: '37mm'}} ><Typography fontSize={16} fontWeight='bold' align="center" >{( (Number(cert.othmarks)+Number(cert.oprmarks)) / (Number(cert.thmarks)+Number(cert.prmarks))*100 ).toFixed(1)} %</Typography></Box>
                        </Stack>

                        <Typography sx={{position: 'absolute', top: '244.8mm', left: '52mm'}} fontSize={16} fontWeight='bold' >{info.issued_on}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container></>
    );
}