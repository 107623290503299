import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Alert, Box, Button, Container, Stack } from '@mui/material'
import { CloudUpload } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import axios from "axios";

export default function Gallery({apiadmin, gallery, snackbar}){

    const [galleryimg, setGalleryimg] = useState([])
    useEffect(() => {
        loadgallery()
    }, [])

    const loadgallery = () => {
        const formData = new FormData()
        formData.append('get-gallery', '')
        axios.post(apiadmin, formData)
        .then(function(res){
            res.data.status === false ? setGalleryimg(res.data) : setGalleryimg(res.data.data)
        })
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [progress, setProgress] = useState('')
    const uploadtogallery = (event) => {
        var formData = new FormData()
        var gimg = event.target.files;
        for(var i=0; i<gimg.length; i++){
            var images = gimg[i]
            formData.append('add-to-gallery[]', images, images.name)
        }
        axios.post(apiadmin, formData, {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                setProgress(Math.round((loaded * 100) / total))
            }
        })
        .then(function(res){
            setProgress('')
            res.data.status === false ? snackbar(res.data.msg) : loadgallery()
        })
    }

    const deleteimg = (img) => {
        snackbar('Deleting...')
        var formData = new FormData()
        formData.append('delete-image-from-gallery', img)
        axios.post(apiadmin, formData)
        .then(function(res){
            snackbar('Deleted')
            res.data.status === true && loadgallery()
        })
    }

    return(
        <Box className='content' >
            <Container>
                <Box textAlign='center' mb={3} >
                    <Button component="label" variant="contained" disabled={progress > 0 ? true : false} startIcon={<CloudUpload />}>
                        {progress > 0 ? progress+'% Uploaded' : 'Upload images' } <VisuallyHiddenInput type="file" accept=".jpeg, .jpg, .png" multiple onChange={(event) => uploadtogallery(event)} />
                    </Button>
                </Box>

                { galleryimg.status === false ? <Alert severity="warning" sx={{mt: 3}} >{galleryimg.msg}</Alert> :
                    <Stack direction='row' useFlexGap flexWrap='wrap' spacing={1} mx='auto' >
                    {galleryimg.map((row) =>
                        <Box sx={{width: '170px', height: '200px', border: 'thin solid #cccccc'}} borderRadius={3} overflow='hidden' mx='auto' key={row.img} onDoubleClick={() => deleteimg(row.img)} >
                            <img src={gallery+row.img} alt={row.img} />
                        </Box>
                    )}
                    </Stack>
                }
            </Container>
        </Box>
    );
}