import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import PublicHeader from './header'

// MUI
import { Box, Container, Grid, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function ComputerCertificate({apipublic}){
    const { studentid } = useParams()
    const { courseid } = useParams()
    const area = useRef()

    const [info, setInfo] = useState([])
    const [cert, setCert] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('get-issued-certificate', studentid)
        formData.append('courseid', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                setInfo(data.data); setCert(data.cert)
            }
        })
    }, [])

    const fmarks = Number(Number(cert.thmarks)+Number(cert.prmarks))
    const obmarks = Number(Number(cert.othmarks)+Number(cert.oprmarks))

    const grade = ((obmarks/fmarks)*100).toFixed(0)

    return(
        <><PublicHeader text='Certificate' printarea={area} isprint={true} /> <Container>
            <Grid container mt={10} >
                <Grid item xs={12} md={12} mx='auto' overflow='scroll' >
                    <Box ref={area} sx={{position: 'relative', width: '295mm', height: '208mm', mx:'auto'}} >
                        <img src="/images/certificate.webp" alt="Computer certificate" style={{width: '296mm', height: '209mm'}} />
                        
                        <Typography sx={{ position: 'absolute', top: '13mm', left: '245mm' }} fontSize={12} fontWeight='bold' fontFamily='monospace' color='darkred' >{info.certificate_no}</Typography>
                        <Typography sx={{ position: 'absolute', top: '82mm', left: '90mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.name}</Typography>
                        <Typography sx={{ position: 'absolute', top: '90.2mm', left: '90mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.guardian}</Typography>
                        <Typography sx={{ position: 'absolute', top: '98mm', left: '90mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.enrollment_no}</Typography>
                        <Typography sx={{ position: 'absolute', top: '106mm', left: '112mm', textTransform: 'uppercase' }} fontSize={16} fontWeight='bold' >{info.c_name} ({info.c_title})</Typography>
                        <Typography sx={{ position: 'absolute', top: '113.5mm', left: '90mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.c_duration} Months</Typography>
                        <Typography sx={{ position: 'absolute', top: '113.5mm', left: '155mm' }} fontSize={18} fontWeight='bold' >{grade >= 80 ? 'Excellent' : grade >= 70 ? 'Good' : grade >= 45 ? 'Satisfactory' : 'Fail'}</Typography>
                        <Typography sx={{ position: 'absolute', top: '129.5mm', left: '90mm' }} fontSize={18} fontWeight='bold' >{info.fr_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '149mm', left: '23mm'}} fontSize={18} fontWeight='bold' >{info.issued_on}</Typography>

                    </Box>
                </Grid>
            </Grid>
        </Container></>
    );
}