import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'
import Masonry from 'react-masonry-css';

// MUI
import { Box, Container } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function GalleryPublic({apipublic, gallery}){

    const [gimg, setGimg] = useState([])
    useEffect(() => {
        const formData = new FormData()
        formData.append('get-gallery-all', '')
        axios.post(apipublic, formData)
        .then(function(res){
            res.data.status === false ? setGimg(res.data) : setGimg(res.data.data)
        })
    }, [])

    return(
        <Box mt={5} >
            <Container>
                <Masonry breakpointCols={1} >
                        {gimg.status !== false && 
                            gimg.map((row, i) => {
                                return(
                                    <img src={gallery+row.img} alt={row.img} key={i} className="gimg" style={{borderRadius: 10}} />
                                );
                            })
                        }
                </Masonry>
            </Container>
        </Box>
    );
}