import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'

// MUI
import { Box, Container, Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { Call, Facebook, Instagram, Shortcut, WhatsApp } from '@mui/icons-material'

export default function Footer(){
    let navi = useNavigate()
    const links = [
        {name: 'Home', link: '/', icon: <Shortcut/>},
        {name: 'Gallery', link: '/', icon: <Shortcut/>},
        {name: 'Verification', link: '/verification', icon: <Shortcut/>},
        {name: 'Courses', link: '/', icon: <Shortcut/>},
        {name: 'Contact', link: '/contact', icon: <Shortcut/>}
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return(
        <Box sx={{background: '#004080', color: 'white'}} mt={5} pt={1} pb={2} >
            <Typography mb={1} textAlign='center' fontWeight='bold' fontFamily='initial' fontSize={{xs: 25, md: 30}} >VIIT COMPUTER EDUCATION</Typography>
            <Divider sx={{bgcolor: '#005BB6', mb: 3}} />
            <Container >
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} justifyContent='space-between' >
                    <Box>
                        <Typography fontWeight='bold' fontSize={20} letterSpacing={2} >SITE MAP</Typography>
                        <Stack direction='row' spacing={2} mt={1} useFlexGap flexWrap="wrap" >
                            { links.map((row, i) =>
                            <NavLink style={{color: 'white'}} to={row.link} key={i} >{row.name}</NavLink> ) }
                        </Stack>
                        <Typography onClick={(event) => handleClick(event)} sx={{cursor: 'pointer'}} mt={0.5} >Login</Typography>

                        <Typography color='white' mt={3} fontWeight='bold' fontSize={20} letterSpacing={2} >Get in touch</Typography>
                        <Stack direction='row' spacing={2} mt={1} useFlexGap flexWrap="wrap" >
                            <IconButton color="inherit" sx={{border: 'thin solid white'}} onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=61554542103986'} ><Facebook/></IconButton>
                            <IconButton color="inherit" sx={{border: 'thin solid white'}} onClick={() => window.location.href = 'https://www.instagram.com/viitcomputereducation/'} ><Instagram/></IconButton>
                            <IconButton color="inherit" sx={{border: 'thin solid white'}} onClick={() => window.location.href = 'https://wa.me/+919534834324'} ><WhatsApp/></IconButton>
                            <IconButton color="inherit" sx={{border: 'thin solid white'}} onClick={() => window.location.href = 'tel: 9534834324'} ><Call/></IconButton>
                        </Stack>
                    </Box>
                    <Box textAlign={{xs: 'left', md: 'right'}} >
                        <Typography fontWeight='bold' fontSize={20} >Walk to our office</Typography>

                        <Typography mt={1} >Head office</Typography>
                        <Typography fontSize={14} >Rishi Bhawan Road, Bara Bazar, Katihar (854105)</Typography>
                        <Typography fontSize={14}>Bihar, India</Typography>

                        <Typography mt={1} >Office</Typography>
                        <Typography fontSize={14}>2nd Gate of D.S. College (Oppo.) (Near Yamaha Showroom), Katihar - 854105</Typography>
                        <Typography fontSize={14}>Bihar, India</Typography>
                    </Box>
                </Stack>
            </Container>
            <Divider sx={{bgcolor: '#005BB6', mb: 2, mt: 3}} />
            <Container>
                <Stack direction='row' spacing={2} mt={1} useFlexGap flexWrap="wrap" justifyContent='space-between' alignItems='center' >
                    <Typography>&#169; copyright viitcomputer. All rights reserved.</Typography>
                    <NavLink to='https://passionfrom.com/' target="_blank" ><img src="https://passionfrom.com/passionfromassets/whitePassionfrom.png" style={{width: 100}} /></NavLink>
                </Stack>
            </Container>
            
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)} >
                <MenuItem onClick={() => navi('/login-franchise')} >Franchise</MenuItem>
                <MenuItem onClick={() => navi('/login-admin')} >Admin</MenuItem>
            </Menu>
        </Box>
    );
}