import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Alert, AlertTitle, Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { Add, Call, CardGiftcard, Class, Computer, East, EditNote, LocationOn, Mail, Memory, Phone, SignalWifi3Bar, VerifiedUser, WhatsApp } from '@mui/icons-material'

import Footer from "./footer";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import axios from "axios";

export default function Home({apipublic, gallery, fullscreen}){
    const navi = useNavigate()
    useEffect(() => {
        //window.scroll(0, 0)
    }, [])
    const [action, setAction] = useState('')
    const [open, setOpen] = useState('')

    const [gimg, setGimg] = useState([])
    useEffect(() => {
        const formData = new FormData()
        formData.append('get-gallery', '')
        axios.post(apipublic, formData)
        .then(function(res){
            res.data.status === false ? setGimg(res.data) : setGimg(res.data.data)
        })
    }, [])

    const [posts, setPosts] = useState([])
    useEffect(() => {
        const formData = new FormData()
        formData.append('get-posts', '')
        axios.post(apipublic, formData)
        .then(function(res){
            res.data.status === false ? setPosts(res.data) : setPosts(res.data.data)
        })
    }, [])

    const [franchise, setFranchise] = useState([])
    const [courses, setCourses] = useState([])
    const [franchiseid, setFranchiseid] = useState('')
    const [courseid, setCourseid] = useState('')
    const [name, setName] = useState('')
    const [guardian, setGuardian] = useState('')
    const [gender, setGender] = useState('');
    const [mobile, setMobile] = useState('')
    const [address, setAddress] = useState('')
    useEffect(() => {
        const formData = new FormData()
        formData.append('join-franchise-data', '')
        axios.post(apipublic, formData)
        .then(function(res) {
            if(res.data.course.status === true){
                setCourses(res.data.course.data)
            } else {
                setCourses(res.data.course)
            }
            if(res.data.franchise.status === true){
                setFranchise(res.data.franchise.data)
            } else {
                setFranchise(res.data.franchise)
            }
        })
    }, [])
    
    const [querymsg, setQuerymsg] = useState('')
    const send_query = () => {
        setQuerymsg('')
        setAction('sending')
        var formData = new FormData()
        formData.append('join-franchise', franchiseid)
        formData.append('courseid', courseid)
        formData.append('name', name)
        formData.append('guardian', guardian)
        formData.append('gender', gender)
        formData.append('mobile', mobile)
        formData.append('address', address)
        axios.post(apipublic, formData)
        .then(function(res){
            setAction('')
            setQuerymsg(res.data.msg)
            if(res.data.status === true){
                setName(''); setGuardian(''); setGender(''); setMobile(''); setAddress('')
            }
        })
    }

    return(
        <Box mt={2} >
            <Dialog open={open === 'openquerybox' ? true : false} fullWidth maxWidth='xs' fullScreen={fullscreen} onClose={() => setOpen('')} >
                <DialogTitle>Join VIIT</DialogTitle><Divider/>
                <DialogContent>
                    <Stack spacing={1.5} mt={1} >
                        <FormControl fullWidth>
                            <InputLabel id="franchise">Franchise</InputLabel>
                            <Select
                            labelId="franchise"
                            id="franchise"
                            value={franchiseid}
                            onChange={(event) => setFranchiseid(event.target.value)}
                            label="Franchise"
                            >
                            { franchise.status !== false && franchise.map((row, i) =>
                            <MenuItem value={row.franchiseid} key={i} >
                                <Box>
                                    <Typography>{row.fr_name}</Typography>
                                    <Typography fontSize={12} >Code: {row.fr_code}</Typography>
                                    <Typography fontSize={12}>Address: {row.fr_address}</Typography>
                                </Box>
                            </MenuItem>) }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="Course">Course</InputLabel>
                            <Select
                            labelId="Course"
                            id="Course"
                            value={courseid}
                            onChange={(event) => setCourseid(event.target.value)}
                            label="Course"
                            >
                            { courses.status !== false && courses.map((row, i) =>
                            <MenuItem value={row.courseid} key={i} >
                                <Box>
                                    <Typography>{row.c_title}</Typography>
                                    <Typography fontSize={12} >{row.c_name}</Typography>
                                </Box>
                            </MenuItem>) }
                            </Select>
                        </FormControl>
                        <TextField variant="outlined" type="text" label='Name' value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField variant="outlined" type="text" label='Guardian' value={guardian} onChange={(event) => setGuardian(event.target.value)} />
                        <FormControl fullWidth>
                            <InputLabel id="gender">Gender</InputLabel>
                            <Select
                            labelId="gender"
                            id="gender"
                            value={gender}
                            onChange={(event) => setGender(event.target.value)}
                            label="Gender"
                            >
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                            <MenuItem value={'Others'}>Others</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField variant="outlined" type="number" label='Mobile' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                        <TextField variant="outlined" type="text" label='Address' value={address} onChange={(event) => setAddress(event.target.value)} />
                        { querymsg && <Alert severity="warning">{querymsg}</Alert> }
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpen('')} >Close</Button>
                    <Button variant="contained" onClick={() => send_query()} disabled={action === 'sending' ? true : false } >{action === 'sending' ? <CircularProgress color="inherit" size={24} /> : 'Send'}</Button>
                </DialogActions>
            </Dialog>
            <Container>
                <Grid container direction='row-reverse' spacing={2} alignItems='center' >
                    <Grid item xs={12} md={7} display='flex' flexDirection={{xs: 'column-reverse', md: 'column'}} >
                        <Stack sx={{cursor: 'pointer'}} padding={2} mt={2} className="shadow gradient1" height={150} borderRadius={3} alignItems='center' justifyContent='center' textAlign='center' onClick={() => navi('/courses')} >
                            <Typography color='white' fontSize={{xs: 30, md: 50}} fontWeight='bold' >Explore our courses...</Typography>
                            <East sx={{color: 'white', fontSize: 40}} />
                        </Stack>
                        <Stack direction='row' spacing={2} mt={2} >
                            <Box height={{xs: 200, md: 350 }} borderRadius={3} className="shadow main-img1" />
                            <Box height={{xs: 200, md: 350 }} borderRadius={3} overflow='hidden' className="shadow main-img2" />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography fontSize={{md: 40, xs: 30}} fontWeight='bold' >Nurturing Minds, Empowering Futures in the Digital World.</Typography>
                        <Typography color='gray' fontSize={16} >Unlocking the Power of Knowledge, One Byte at a Time.</Typography>

                        <Stack spacing={1} mt={3} direction='row' useFlexGap flexWrap="wrap" >
                            <Button variant="contained" className="gradient1" size="large" startIcon={<Phone/>} onClick={() => window.location.href = 'tel: 8271090663'} >Office</Button>
                            <Button variant="contained" className="gradient1" size="large" startIcon={<Phone/>} onClick={() => window.location.href = 'tel: 9534834324'} >Head Office</Button>
                            <Button variant="contained" className="gradient1" size="large" startIcon={<Add/>} onClick={() => setOpen('openquerybox')} >Join now</Button>
                        </Stack>

                        <Box mt={3} sx={{border: 'thin solid #cccccc'}} padding={2} borderRadius={2} >
                            <Typography color='orange' fontWeight='bold' >A Unit of - Vishesh Educational & Welfare Society</Typography>
                            <Typography color='secondary.main' >Regd. Under Society Registration Act 21, 1860 (No - 542/2013-14)</Typography>
                            <Typography color='primary.main' >An ISO 9001:2008 Certified Organization</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ mt: {xs: 5, md: 15} }} >

                <Stack mb={5} direction={{xs: 'column', md: 'row'}} spacing={2} >
                    <Box className='gradient1' borderRadius={3} p={2} textAlign='center' pb={5} >
                        <Typography fontSize={150} fontWeight='bold' color='white' >15+</Typography>
                        <Typography fontSize={20} fontWeight='bold' mt={-5} color='white' >Years of journey</Typography>
                    </Box>
                    <Box sx={{border: 'thin solid #cccccc', width: '100%'}} borderRadius={2} display='flex' flexDirection='column' alignItems='center' justifyContent='center' >
                        <Box padding={2} >
                            <Typography fontSize={25} mb={2} color='darkblue' fontWeight='bold' >हमारी मुख्य विशेषताएं...</Typography>
                            <Typography fontSize={{md: 40, xs: 30}} fontWeight='bold' color='darkred' >30 दिनों की पढ़ाई से संतुष्ट नहीं होने पर 100% फीस वापस होने की गारंटी!</Typography>
                        </Box>
                    </Box>
                </Stack>

                <Stack mb={5} direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{border: 'thin solid #cccccc'}} borderRadius={2} padding={2} >
                    <Box sx={{width: {xs: '100%', md: '50%'}}} borderRadius={3} overflow='hidden' textAlign={{xs: 'center', md: 'left'}} >
                        <img src="/logo.jpg" alt="viit" style={{width: '70%'}} />
                    </Box>
                    <Box sx={{ width: '100%' }} display='flex' alignItems='center' >
                        <Box>
                            <Typography color='gray' fontSize={15} >VIIT Computer Education is a leading institute dedicated to providing cutting-edge computer education, empowering individuals with the skills and knowledge needed to thrive in the rapidly evolving digital landscape. Our institute is committed to excellence in education, offering a comprehensive range of courses designed to meet the diverse needs of students aspiring to build a successful career in the field of technology.</Typography>
                            <Typography fontSize={16} fontWeight='bold' color='secondary' mt={2} mb={1} >Most interested courses</Typography>
                            <Stack direction='row' spacing={1} useFlexGap flexWrap="wrap" >
                                <Button variant="outlined" color="secondary" >DCA</Button>
                                <Button variant="outlined" color="secondary" >ADCA</Button>
                                <Button variant="outlined" color="secondary" >Tally ERP 9</Button>
                                <Button variant="outlined" color="secondary" >Web Development</Button>
                                <Button variant="contained" color="secondary" startIcon={<Add/>} onClick={() => setOpen('openquerybox')} >Join now</Button>
                            </Stack>
                        </Box>
                    </Box>
                </Stack>

                {posts.status !== false && <Stack mb={5} spacing={2} sx={{border: 'thin solid #cccccc'}} borderRadius={2} padding={2} >
                    <Typography fontSize={50} fontWeight='bold' textAlign='center' color='red' >Whats new?</Typography>
                    {posts.map((row, i) =>
                    <Alert key={i} severity="warning" >
                        <AlertTitle>{new Date(row.post_date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: '2-digit'})}</AlertTitle>
                        <Typography fontWeight={'bold'} >{row.post}</Typography>
                    </Alert> )}
                </Stack>}

                <Stack className='gradient1' mt={5} padding={1} borderRadius={2} direction='row' alignItems='center' justifyContent='center' >
                    <CardGiftcard sx={{fontSize: 50, color: 'white'}} />
                    <Typography fontSize={30} color='white' fontWeight='bold' textAlign='center' > Facilities</Typography>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={3}>
                    <ListItem sx={{p: 2.5, border: 'thin solid #cccccc', borderRadius: 2}} >
                        <ListItemIcon><SignalWifi3Bar fontSize="large" sx={{color: '#FF4F00'}} /></ListItemIcon>
                        <ListItemText>Free WIFI</ListItemText>
                    </ListItem>
                    <ListItem sx={{p: 2.5, border: 'thin solid #cccccc', borderRadius: 2}} >
                        <ListItemIcon><Memory fontSize="large" sx={{color: '#007FFF'}} /></ListItemIcon>
                        <ListItemText>Digital Classroom</ListItemText>
                    </ListItem>
                    <ListItem sx={{p: 2.5, border: 'thin solid #cccccc', borderRadius: 2}} >
                        <ListItemIcon><Class fontSize="large" sx={{color: '#008B8B'}} /></ListItemIcon>
                        <ListItemText>Structured Classroom</ListItemText>
                    </ListItem>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2}>
                    <ListItem sx={{p: 2.5, border: 'thin solid #cccccc', borderRadius: 2}} >
                        <ListItemIcon><EditNote fontSize="large" sx={{color: '#FF4F00'}} /></ListItemIcon>
                        <ListItemText>Examination</ListItemText>
                    </ListItem>
                    <ListItem sx={{p: 2.5, border: 'thin solid #cccccc', borderRadius: 2}} >
                        <ListItemIcon><Computer fontSize="large" sx={{color: '#007FFF'}} /></ListItemIcon>
                        <ListItemText>Computer Lab</ListItemText>
                    </ListItem>
                    <ListItem sx={{p: 2.5, border: 'thin solid #cccccc', borderRadius: 2}} >
                        <ListItemIcon><VerifiedUser fontSize="large" sx={{color: '#008B8B'}} /></ListItemIcon>
                        <ListItemText>Certificate Courses</ListItemText>
                    </ListItem>
                </Stack>

                <Grid container spacing={2} mt={5} >
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined" >
                            <CardContent>
                                <Swiper
                                initialSlide={3}
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={'auto'}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                coverflowEffect={{
                                rotate: 50,
                                stretch: 2,
                                depth: 200,
                                modifier: 1,
                                slideShadows: true,
                                }}
                                pagination={true}
                                modules={[EffectCoverflow, Pagination]}
                                className="mySwiper"
                            >
                                { gimg.status !== false && gimg.map((row, i) =>
                                <SwiperSlide key={i} style={{width: '300px', height: '300px', borderRadius: 10, overflow: 'hidden'}} >
                                    <img src={gallery+row.img} alt='students' />
                                </SwiperSlide> ) }
                            </Swiper>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Stack className='gradient1' mt={5} padding={1} borderRadius={2} direction='row' alignItems='center' justifyContent='center' >
                    <LocationOn sx={{fontSize: 50, color: 'white'}} />
                    <Typography fontSize={30} color='white' fontWeight='bold' textAlign='center' > Everywhere VIIT</Typography>
                </Stack>

                <Grid container>
                    <Grid item xs={12} md={12} mx='auto' mt={2} >
                        <Card sx={{width: '100%'}} >
                            <CardContent>
                                <Stack direction='row' alignItems='center' justifyContent='start' >
                                    <Avatar sx={{width: 100, height: 100}} src="/logo.jpg" alt="Director of VIIT" />
                                    <Box ml={3} >
                                        <Typography fontWeight='bold' color='darkblue' fontSize={30} >Dhiraj Kumar Sinha</Typography>
                                        <Typography fontSize={14} className="text-gradient1" >Founder of VIIT Computer Education</Typography>
                                    </Box>
                                </Stack>
                                <Box mt={2} mb={2} >
                                    <Typography fontWeight='bold' className="text-gradient1" mt={2} fontSize={14} >15+ Years of journey with VIIT</Typography>
                                    <Typography fontSize={14} color='gray' >Dhiraj Kumar Sinha, the visionary founder of VIIT, is a dynamic leader in the field of computer education. With a passion for empowering individuals through technology, he has established VIIT as a premier institute dedicated to fostering innovation and excellence. Dhiraj's commitment to providing cutting-edge education reflects his vision for a tech-savvy and skilled workforce.</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={12} mx='auto' mt={2} >
                        <Card sx={{width: '100%'}} >
                            <CardContent>
                                <Stack direction='row' alignItems='center' justifyContent='start' >
                                    <Avatar sx={{width: 100, height: 100}} src="/images/mukesh-sir.jpg" alt="Director of VIIT" />
                                    <Box ml={3} >
                                        <Typography fontWeight='bold' color='darkblue' fontSize={30} >Mukesh Kumar Roy</Typography>
                                        <Typography fontSize={14} className="text-gradient1" >Director of VIIT Computer Education</Typography>
                                    </Box>
                                </Stack>
                                <Box mt={2} mb={2} >
                                    <Typography fontSize={14} color='gray' >Dear Esteemed Students, I hope this message finds you well and thriving in your academic journey at our computer institute. As the director, I am immensely proud to witness your dedication and enthusiasm for learning. Remember that education is a lifelong pursuit, and each day in our institute is an opportunity to enhance your skills and knowledge. Embrace the challenges, ask questions, and collaborate with your peers to make the most of your educational experience. Our commitment is to provide you with the best resources, guidance, and a conducive learning environment. I encourage you to explore beyond the classroom, engage in practical projects, and stay curious about the ever-evolving world of technology. Your success is our priority, and we believe in your potential to make a significant impact in the field of computer science. If you ever need support or have ideas to share, my door is always open. Wishing you a fulfilling and rewarding academic year.</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Stack spacing={2} direction={{xs: 'column', md: 'row'}} mt={2} >
                    <Card sx={{width: '100%'}} >
                        <CardContent>
                            <Stack direction='row' alignItems='center' justifyContent='start' >
                                <Avatar sx={{width: 100, height: 100}} src="/images/manjit-sir.jpeg" alt="Center incharge of VIIT" />
                                <Box ml={3} >
                                    <Typography fontWeight='bold' color='darkblue' fontSize={30}>Manjit Kumar</Typography>
                                    <Typography fontSize={14} color='gray' >Center Incharge</Typography>
                                </Box>
                            </Stack>
                            <Box mt={2} mb={2} >
                                <Typography fontSize={14} fontWeight='bold' className="text-gradient1">8+ Years of experience at VIIT</Typography>
                                <Typography mt={1} color='gray' >Office address: 2nd Gate of D.S. College (Oppo.) (Near Yamaha Showroom)</Typography>
                            </Box><Divider/>
                            <Stack direction='row' alignItems='center' mt={2} spacing={1} >
                                <Button startIcon={<Mail/>} variant="outlined" color="secondary" onClick={() => window.location.href = 'mailto: manjitktr@gmail.com'} >Mail</Button>
                                <Button startIcon={<WhatsApp/>} variant="outlined" color="secondary" onClick={() => window.location.href = 'https://wa.me/+918271090663'} >WhatsApp</Button>
                                <Button startIcon={<Call/>} variant="outlined" color="secondary" onClick={() => window.location.href = 'tel: 8271090663'} >Call</Button>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{width: '100%'}} >
                        <CardContent>
                            <Stack direction='row' alignItems='center' justifyContent='start' >
                                <Avatar sx={{width: 100, height: 100}} src="/images/vivek-sir.jpeg" alt="Center incharge of VIIT" />
                                <Box ml={3} >
                                    <Typography fontWeight='bold' color='darkblue' fontSize={30} >Vivek Kumar</Typography>
                                    <Typography fontSize={14} color='gray' >Center Incharge</Typography>
                                </Box>
                            </Stack>
                            <Box mt={2} mb={2} >
                                <Typography fontSize={14} fontWeight='bold' className="text-gradient1" mt={1} >8+ Years of experience at VIIT</Typography>
                                <Typography mt={1} color='gray' >Office address: Rishi Bhawan Road, Bara Bazar, Katihar (854105), Bihar</Typography>
                            </Box><Divider/>
                            <Stack direction='row' alignItems='center' mt={2} spacing={1} >
                                <Button startIcon={<Mail/>} variant="outlined" color="secondary" onClick={() => window.location.href = 'mailto: viitcomputer2013@gmail.com'} >Mail</Button>
                                <Button startIcon={<WhatsApp/>} variant="outlined" color="secondary" onClick={() => window.location.href = 'https://wa.me/+919534834324'} >WhatsApp</Button>
                                <Button startIcon={<Call/>} variant="outlined" color="secondary" onClick={() => window.location.href = 'tel: 9534834324'} >Call</Button>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
        <Footer/></Box>
    );
}