import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import PublicHeader from "../public/header";

// MUI
import { Box, Container, Divider, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import axios from "axios";

export default function AdmissionForm({apiglobal}){

    const area = useRef()
    const { studentid } = useParams()
    const { courseid } = useParams()

    const [student, setStudent] = useState([])
    const [course, setCourse] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('admission-form-details', studentid)
        formData.append('courseid', courseid)
        axios.post(apiglobal, formData)
        .then(function(res){
            if(res.data.student.status === true){
                setStudent(res.data.student.data)
            }
            if(res.data.course.status === true){
                setCourse(res.data.course.data)
            }
        })
    }, [studentid, courseid])

    return(
        <Box><PublicHeader text={'Admission form'} isprint={true} printarea={area} />
            <Container sx={{mt: 2, overflow: 'auto'}} >
                <Box ref={area} p={2} pl={5} pr={5} sx={{width: '209mm', height: '296mm'}} mx={'auto'} >
                    <Stack mb={1} direction='row' justifyContent={'space-between'} alignItems={'center'} >
                        <Box>
                            <Typography fontSize={40} fontWeight='bold' color={'primary.main'} >VIIT COMPUTER EDUCATION</Typography>
                            <Typography fontSize={14} color={'secondary.main'} >Regd. Under Society Registration Act 21, 1860 (No - 542/2013-14)</Typography>
                            <Typography fontSize={14} color={'secondary.main'} >An ISO 9001:2008 Certified Organization</Typography>
                        </Box>
                        <Box>
                            <img src="/logo.png" style={{width: 120}} />
                        </Box>
                    </Stack><Divider/>

                    <Typography fontSize={16} fontWeight='bold' mt={2} >Personal Details</Typography>
                    <Table >
                        <TableBody>
                            <TableRow>
                                <TableCell>Name:</TableCell>
                                <TableCell>{student.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Father's name</TableCell>
                                <TableCell>{student.guardian}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Gender</TableCell>
                                <TableCell>{student.gender}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Date of birth</TableCell>
                                <TableCell>{new Date(student.birth).toLocaleDateString(undefined, {day: '2-digit', month: 'short', year: 'numeric'})}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table >
                        <TableBody>
                            <Typography fontSize={16} fontWeight='bold' mt={3} >Permanent Address</Typography>
                            <TableRow>
                                <TableCell>Full address</TableCell>
                                <TableCell>{student.address}, {student.city}, {student.state}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table >
                        <TableBody>
                            <TableRow>
                                <TableCell>Postal code</TableCell>
                                <TableCell>{student.postal}</TableCell>
                                <TableCell>Aadhaar no.</TableCell>
                                <TableCell>{student.aadhaar}</TableCell>
                                <TableCell>Mobile no.</TableCell>
                                <TableCell>{student.mobile}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table>
                        <Typography fontSize={16} fontWeight='bold' mt={3} >Selected course</Typography>
                        <TableBody>
                            <TableRow>
                                <TableCell>Course</TableCell>
                                <TableCell>{course.c_name} ({course.c_title})</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>{course.c_duration} Months</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table >
                        <Typography fontSize={16} fontWeight='bold' mt={3} >Academic Qualification</Typography>
                        <TableBody>   
                            <TableRow>  
                                <TableCell>Examination</TableCell>
                                <TableCell>Board/University</TableCell>
                                <TableCell>Year of passing</TableCell>
                                <TableCell>Marks obtained</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{student.exam}</TableCell>
                                <TableCell>{student.board}</TableCell>
                                <TableCell>{student.passed_year}</TableCell>
                                <TableCell>{student.marks}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Typography fontSize={16} fontWeight='bold' mt={3} >Declaration</Typography>
                    <Typography fontSize={14} >I hereby declare that all above details are true to the best of my knowledge. I also will abide by the rules of the institute.</Typography>

                    <Stack direction={'row'} justifyContent={'space-between'} mt={5} >
                        <Typography fontSize={16} fontWeight='bold' mt={3} >Center Head Signature</Typography>
                        <Typography fontSize={16} fontWeight='bold' mt={3} >Student Signature</Typography>
                    </Stack>
                </Box>
            </Container>
        </Box>
    );
}