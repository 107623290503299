import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Box, Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import {  } from '@mui/icons-material'

export default function StudentUpload({auth, apiglobal, snackbar}){
    let navi = useNavigate()
    const { studentid } = useParams()

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [guardian, setGuardian] = useState('')
    const [mother, setMother] = useState('')
    const [address, setAddress] = useState('')
    const [post, setPost] = useState('')
    const [pstation, setPstation] = useState('')
    const [city, setCity] = useState('')
    const [dist, setDist] = useState('')
    const [state, setState] = useState('')
    const [postal, setPostal] = useState('')
    const [aadhaar, setAadhaar] = useState('')
    const [mobile, setMobile] = useState('')
    const [birth, setBirth] = useState('')
    const [gender, setGender] = useState('')
    const [nation, setNation] = useState('')
    const [status, setStatus] = useState('active')
    const [exam, setExam] = useState('')
    const [board, setBoard] = useState('')
    const [pyear, setPyear] = useState('')
    const [marks, setMarks] = useState('')
    const [joindate, setJoindate] = useState('')

    useEffect(() => {
        var formData = new FormData()
        formData.append('get-student-detail', studentid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status === true){
                setEmail(data.data.email); setName(data.data.name); setGuardian(data.data.guardian); setMother(data.data.mother); setAddress(data.data.address); setPost(data.data.post); setPstation(data.data.pstation); setCity(data.data.city); setDist(data.data.dist); setState(data.data.state); setPostal(data.data.postal); setAadhaar(data.data.aadhaar); setMobile(data.data.mobile); setBirth(data.data.birth); setGender(data.data.gender); setNation(data.data.nation); setStatus(data.data.status); setJoindate(data.data.joindate); setExam(data.data.exam); setBoard(data.data.board); setPyear(data.data.passed_year); setMarks(data.data.marks)
            }
        })
    }, [studentid])

    const [uploading, setUploading] = useState(false)
    const upload = () => {
        setUploading(true)
        var formData = new FormData()
        formData.append('add-student', studentid)
        formData.append('email', email); formData.append('name', name); formData.append('guardian', guardian); formData.append('mother', mother); formData.append('address', address); formData.append('post', post); formData.append('pstation', pstation); formData.append('city', city); formData.append('dist', dist); formData.append('state', state); formData.append('postal', postal); formData.append('aadhaar', aadhaar); formData.append('mobile', mobile); formData.append('birth', birth); formData.append('gender', gender); formData.append('nation', nation); formData.append('status', status); formData.append('joindate', joindate); formData.append('exam', exam); formData.append('board', board); formData.append('passedyear', pyear); formData.append('marks', marks); 
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.status === false){
                snackbar(data.message)
            } else {
                navi(-1)
            }
        })
    }

    return(
        <Box className='content' >
            <Container>
                <Back text='Student upload'/>

                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} >
                    <TextField fullWidth variant="outlined" label='Name' type="text" value={name} onChange={(event) => setName(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Father name*' type="text" value={guardian} onChange={(event) => setGuardian(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Mother name' type="text" value={mother} onChange={(event) => setMother(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Email' type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                </Stack>
                <Stack mt={2} direction={{xs: 'column', md: 'row'}} spacing={2} >
                    <TextField fullWidth variant="outlined" label='Mobile' type="number" value={mobile} onChange={(event) => setMobile(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Address' type="text" value={address} onChange={(event) => setAddress(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" label='Post office' type="text" value={post} onChange={(event) => setPost(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Police station' type="text" value={pstation} onChange={(event) => setPstation(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='City' type="text" value={city} onChange={(event) => setCity(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='District' type="text" value={dist} onChange={(event) => setDist(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='State' type="text" value={state} onChange={(event) => setState(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" label='Postal code' type="number" value={postal} onChange={(event) => setPostal(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Aadhaar' type="number" value={aadhaar} onChange={(event) => setAadhaar(event.target.value)} />
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" helperText='Birth' type="date" value={birth} onChange={(event) => setBirth(event.target.value)} />
                    <FormControl fullWidth >
                        <InputLabel>Gender</InputLabel>
                        <Select label='Gender' value={gender} onChange={(event) => setGender(event.target.value)} >
                            <MenuItem value='Male' >Male</MenuItem>
                            <MenuItem value='Female' >Female</MenuItem>
                            <MenuItem value='Others' >Others</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth >
                        <InputLabel>Nation</InputLabel>
                        <Select label='Nation' value={nation} onChange={(event) => setNation(event.target.value)} >
                            <MenuItem value='Indian' >INDIAN</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" helperText='Join date*' type="date" value={joindate} onChange={(event) => setJoindate(event.target.value)} />
                    <FormControl fullWidth >
                        <InputLabel>Status*</InputLabel>
                        <Select label='Status' value={status} onChange={(event) => setStatus(event.target.value)} >
                            <MenuItem value='active' >Active</MenuItem>
                            <MenuItem value='pending' >Pending</MenuItem>
                            <MenuItem value='archive' >Archive</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <FormControl fullWidth >
                        <InputLabel>Examination</InputLabel>
                        <Select label='Examination' value={exam} onChange={(event) => setExam(event.target.value)} >
                            <MenuItem value='6th' >6th</MenuItem>
                            <MenuItem value='7th' >7th</MenuItem>
                            <MenuItem value='8th' >8th</MenuItem>
                            <MenuItem value='9th' >9th</MenuItem>
                            <MenuItem value='10th' >10th</MenuItem>
                            <MenuItem value='intermediate' >Intermediate</MenuItem>
                            <MenuItem value='graduate' >Graduate</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField fullWidth variant="outlined" label='Board' type="text" value={board} onChange={(event) => setBoard(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Passed year' type="text" value={pyear} onChange={(event) => setPyear(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Obtained marks' type="text" value={marks} onChange={(event) => setMarks(event.target.value)} />
                </Stack>
                <Stack textAlign='right' spacing={1} direction='row' justifyContent='end' mt={2} >
                    <Button variant='outlined' onClick={() => navi(-1)} >Back</Button>
                    <Button variant="contained" sx={{width: '100%'}} onClick={() => upload()} disabled={auth === 'admin' ? true : uploading} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                </Stack>
            </Container>
        </Box>
    );
}