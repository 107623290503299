import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Alert, Box, Button, Card, CardContent, Container, Divider, FormControl, Grid, IconButton, LinearProgress, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { AccountTree, Add, Edit, Visibility } from '@mui/icons-material'

export default function Students({auth, apiglobal}){
    let navi = useNavigate()

    const [searchquery, setSearchquery] = useState('')

    useEffect(() => {
        getStudents()
    }, [])
    const [students, setStudents] = useState([])
    const [loading, setLoading] = useState(true)
    const getStudents = () => {
        var formData = new FormData()
        formData.append('get-students', searchquery)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.status === false){
                setStudents(data)
            } else {
                setStudents(data.data)
            }
        })
    }

    return(
        <Box className='content' >
            <Container>
                <FormControl variant="standard" fullWidth>
                    <TextField fullWidth variant="standard" label='Search by student name' value={searchquery} onChange={(event) => setSearchquery(event.target.value)} onKeyDown={(event) => event.key === 'Enter' && getStudents()} />
                </FormControl>

                <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} mt={1} >
                    <Typography fontSize={14} fontWeight='bold' >Students</Typography>
                    <Box>
                        {auth === 'franchise' ? <Button startIcon={<Add/>} variant="outlined" onClick={() => navi('/controller/student-upload/0')} >Add Student</Button> : null }
                    </Box>
                </Stack>
                
                <Grid container>
                    <Grid item xs={12} md={7} mx='auto' >
                        { loading ? <LinearProgress color="primary" /> : students.status === false ? <Alert severity="warning">{students.message}</Alert> :
                        students.map((data, i) =>
                        <Card key={i} sx={{mb: 1}} >
                            <CardContent>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' >
                                    <Box>
                                        <Typography fontSize={20} fontWeight='bold' noWrap >{data.name}</Typography>
                                        <Typography fontSize={14} color='gray' >{data.joindate}</Typography>
                                    </Box>
                                    <Stack direction='row' >
                                        <IconButton sx={{background: '#F5F5F5'}} onClick={() => navi(`/controller/student-upload/${data.studentid}`)} >{auth === 'admin' ? <Visibility/> : <Edit/>}</IconButton>
                                        <IconButton sx={{background: '#F5F5F5', ml: 0.5}} onClick={() => navi(`/controller/student-info/${data.studentid}`)} ><AccountTree/></IconButton>
                                    </Stack>
                                </Stack>
                                <Box mt={1} mb={1} >
                                    <Typography fontSize={14} color='gray' >Email - {data.email}</Typography>
                                    <Typography fontSize={14} color='gray' >Institute - {data.fr_name}</Typography>
                                </Box><Divider/>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' mt={1} >
                                    <Typography fontSize={14} color='gray' >Status</Typography>
                                    <Typography fontSize={14} color={data.status === 'active' ? 'green' : data.status === 'pending' ? 'orange' : '#007FFF'} fontWeight='bold' >{data.status}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>) }
                    </Grid>
                </Grid>
            </Container>            
        </Box>
    );
}