import React, { useEffect } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

import Footer from "./footer";

export default function CoursesList(){

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return(
        <Box mt={3} >
            <Container>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Diploma in Computer Application</Typography>
                                <Typography color='white' fontSize={12} >Title - DCA</Typography>
                                <Typography color='white' fontSize={12} >Duration - 6 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' >Fundamental</Typography>
                                <Typography fontSize={14} >About computer technology, Hardware component, Software component, Computer typing(Hindi/English)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Operating System</Typography>
                                <Typography fontSize={14} >MS-DOS, MS-Windows XP/WIN7/8/10/11, Notepad, Wordpad, Ms-Paint, Calculator, Media Player</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >MS-Office</Typography>
                                <Typography fontSize={14} >MS-Word, MS-Excel, MS-Powerpoint, MS-Access</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Internet</Typography>
                                <Typography fontSize={14} >(Google SE, Gmail, Google Drive, Facebook, Map, Twitter, Text chatting, Video calling, YouTube, Translator, E-Book, Remote Login, Online Form Apply, Aadhar Card Download/Correction, Online mock test, RTPS, E-Commerce, Online Bill Payment, Land Muration, Land Revenue, PAN Card, Air & Railway, Reservation & More)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Extra</Typography>
                                <Typography fontSize={14} >Downloading, Scanning, Printing, DVD Write, Antivirus, Assignment & Project Work</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Diploma in Financial Accounting</Typography>
                                <Typography color='white' fontSize={12} >Title - DFA</Typography>
                                <Typography color='white' fontSize={12} >Duration - 6 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' >Fundamental</Typography>
                                <Typography fontSize={14} >About computer technology, Hardware component, Software component, Computer typing(Hindi/English)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Operating System</Typography>
                                <Typography fontSize={14} >MS-DOS, MS-Windows XP/WIN7/8/10/11, Notepad, Wordpad, Ms-Paint, Calculator, Media Player</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Accountancy</Typography>
                                <Typography fontSize={14} >Account Basics, Rules of Account, Texation with GST, Manual Accounting, Implementation on Tally ERP9/PRIME, Zero Value Entries, Maintain Multiple Godowns, Actual & Billed Quantities, Price List, Bill of Material, etc.</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Extra</Typography>
                                <Typography fontSize={14} >Downloading, Scanning, Printing, DVD Write, Antivirus, Assignment & Project Work</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Diploma in Desktop Publishing</Typography>
                                <Typography color='white' fontSize={12} >Title - DTP</Typography>
                                <Typography color='white' fontSize={12} >Duration - 6 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' >Fundamental</Typography>
                                <Typography fontSize={14} >About computer technology, Hardware component, Software component, Computer typing(Hindi/English)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Operating System</Typography>
                                <Typography fontSize={14} >MS-DOS, MS-Windows XP/WIN7/8/10/11, Notepad, Wordpad, Ms-Paint, Calculator, Media Player</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Included</Typography>
                                <Typography fontSize={14} >MS-Word, Multimedia, Photoshop, Coreldraw, pagemaker, Downloading, Scanning, printing, DVD Write, Antivirus Assignment & Project Work</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Digital Marketing</Typography>
                                <Typography color='white' fontSize={12} >Title - DM</Typography>
                                <Typography color='white' fontSize={12} >Duration - 6 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' >Topics</Typography>
                                <Typography fontSize={14} >Introduction of Digital Marketing, Planning & Creating a website, Search Engine Optimisation (SEO), Search Engine Marketing (SEM), Social Media Marketing (SMM), Content Strategy, Web & Analytics, E-mail Markeing, Mobile Marketing, E-commerce Marketing, Video Markeing, Google Adsence and Affiliate Marketing, Online Repuration Management, Assignment & Project work.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Advance Diploma in Computer Application</Typography>
                                <Typography color='white' fontSize={12} >Title - ADCA</Typography>
                                <Typography color='white' fontSize={12} >Duration - 12 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' >Fundamental</Typography>
                                <Typography fontSize={14} >About computer technology, Hardware component, Software component, Computer typing(Hindi/English)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Operating System</Typography>
                                <Typography fontSize={14} >MS-DOS, MS-Windows XP/WIN7/8/10/11, Notepad, Wordpad, Ms-Paint, Calculator, Media Player</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >MS-Office</Typography>
                                <Typography fontSize={14} >MS-Word, MS-Excel, MS-Powerpoint, MS-Access</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Accountancy</Typography>
                                <Typography fontSize={14} >Account Basics, Rules of Account, Texation with GST, Manual Accounting, Implementation on Tally ERP9/PRIME, Zero Value Entries, Maintain Multiple Godowns, Actual & Billed Quantities, Price List, Bill of Material, etc.</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >HTML</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Internet</Typography>
                                <Typography fontSize={14} >(Google SE, Gmail, Google Drive, Facebook, Map, Twitter, Text chatting, Video calling, YouTube, Translator, E-Book, Remote Login, Online Form Apply, Aadhar Card Download/Correction, Online mock test, RTPS, E-Commerce, Online Bill Payment, Land Muration, Land Revenue, PAN Card, Air & Railway, Reservation & More)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Extra</Typography>
                                <Typography fontSize={14} >Downloading, Scanning, Printing, DVD Write, Antivirus, Assignment & Project Work</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Language</Typography>
                                <Typography color='white' fontSize={12} >Title - Language</Typography>
                                <Typography color='white' fontSize={12} >Duration - By language</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' >Languages</Typography>
                                <Typography fontSize={14} >C Language, OOP language c++, J2SE (Core Java), J2EE, J2ME, C#, VB.NET, ASP.NET, GAMBAS, PYTHON, Java Script</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Advance Diploma in Computer Application</Typography>
                                <Typography color='white' fontSize={12} >Title - ADCA+</Typography>
                                <Typography color='white' fontSize={12} >Duration - 12 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' >Fundamental</Typography>
                                <Typography fontSize={14} >About computer technology, Hardware component, Software component, Computer typing(Hindi/English)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Operating System</Typography>
                                <Typography fontSize={14} >MS-DOS, MS-Windows XP/WIN7/8/10/11, Notepad, Wordpad, Ms-Paint, Calculator, Media Player</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >MS-Office</Typography>
                                <Typography fontSize={14} >MS-Word, MS-Excel, MS-Powerpoint, MS-Access</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Accountancy</Typography>
                                <Typography fontSize={14} >Account Basics, Rules of Account, Texation with GST, Manual Accounting, Implementation on Tally ERP9/PRIME, Zero Value Entries, Maintain Multiple Godowns, Actual & Billed Quantities, Price List, Bill of Material, etc.</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Multimedia, Photoshop, CorelDraw, Pagemaker</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Quick web designing</Typography>
                                <Typography fontSize={14} >Client - Server Architechture, Web server installation (Xampp/Tomcat), CMD(Drupal, Joomla, WordPress), Deployment and Hosting - Local Domain Registration, Remote server Web Server and Hosting, A live website design</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Internet</Typography>
                                <Typography fontSize={14} >(Google SE, Gmail, Google Drive, Facebook, Map, Twitter, Text chatting, Video calling, YouTube, Translator, E-Book, Remote Login, Online Form Apply, Aadhar Card Download/Correction, Online mock test, RTPS, E-Commerce, Online Bill Payment, Land Muration, Land Revenue, PAN Card, Air & Railway, Reservation & More)</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Harddisk Formatting, Windows Installation</Typography>

                                <Typography fontSize={16} fontWeight='bold' mt={1} >Extra</Typography>
                                <Typography fontSize={14} >Downloading, Scanning, Printing, DVD Write, Antivirus, Assignment & Project Work</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Web Application Development</Typography>
                                <Typography color='white' fontSize={12} >Title - WAD+</Typography>
                                <Typography color='white' fontSize={12} >Duration - 6 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' mt={1} >Topics</Typography>
                                <Typography fontSize={14} >Concept of Web Application, Architecture of Web Application, HTML 5.0, CSS, Java Script, Angular JS, Creation of web pages and forms, Database (MySQL, Table, View, SQL), Procedure and function, Concept of web service, Data Carrier (XML/JSON), Implementation of built in web services, Design and deployment of web service, Business login of web application, Database connection with web application, CRUD Example in Web application, Deployment of Web Application on Remote server.</Typography>
                            </Box>
                        </Box>

                        <Box mt={2} >
                            <Box className='gradient1' padding={1.5} borderRadius={2} >
                                <Typography fontSize={20} fontWeight='bold' color='white' mb={1} >Web Application Development</Typography>
                                <Typography color='white' fontSize={12} >Title - WAD+</Typography>
                                <Typography color='white' fontSize={12} >Duration - 6 Months</Typography>
                            </Box>
                            <Box sx={{background: '#F5F5F5'}} padding={1.5} borderRadius={2} mt={0.5} >
                                <Typography fontSize={16} fontWeight='bold' mt={1} >Topics</Typography>
                                <Typography fontSize={14} >Concept of cross platform & Mobile App Development, HTML 5.0, CSS, Java Script, Angular JS, Creation of web pages and forms, Database (MySQL, Table, View, SQL), Procedure and function, Concept of web service, Data Carrier (XML/JSON), Implementation of built in web services, Design and deployment of web service, SQL Lite - Mobile database, Database connection with Mobile Application, CRUD Example in Mobile Application, Deployment and Hosting of Mobile Application, Assignment & Project Work.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        <Footer/></Box>
    );
}