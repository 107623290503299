import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Alert, Box, Button, CircularProgress, Container, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { Edit } from '@mui/icons-material'

export default function CourseMarks({apiadmin, snackbar}){
    let navi = useNavigate()
    const { courseid } = useParams()

    const [type, setType] = useState('')
    const [fmark, setFmark] = useState('')
    const [pmark, setPmark] = useState('')

    useEffect(() => {
        getMarks()
    }, [])

    const [marks, setMarks] = useState([])
    const getMarks = () => {
        var formData = new FormData()
        formData.append('list-course-marks', courseid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status == false){
                setMarks(data)
            } else {
                setMarks(data.data)
            }
        })
    }

    const [uploading, setUploading] = useState(false)
    const upload = () => {
        setUploading(true)
        var formData = new FormData()
        formData.append('add-course-marks', courseid)
        formData.append('type', type); formData.append('fmark', fmark); formData.append('pmark', pmark)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.status == false){
                snackbar(data.msg)
            } else {
                snackbar(data.msg); getMarks(); setType(''); setFmark(''); setPmark('')
            }
        })
    }

    const marksDetail = (type) => {
        window.scroll(0, 0)
        var formData = new FormData()
        formData.append('get-course-marks', courseid)
        formData.append('type', type)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status == false){
                snackbar(data.msg)
            } else {
                setType(data.data.marks_for); setFmark(data.data.full_marks); setPmark(data.data.pass_marks)
            }
        })

    }

    return(
        <Box className='content' >
            <Container>
                <Back text='Franchise upload'/>

                <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                        value={type}
                        label="Type"
                        onChange={(event) => setType(event.target.value)}
                        >
                            <MenuItem value='THEORY'>Theory</MenuItem>
                            <MenuItem value='PRACTICAL'>Practical</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mt={2} >
                    <TextField fullWidth variant="outlined" label='Full marks' type="number" value={fmark} onChange={(event) => setFmark(event.target.value)} />
                    <TextField fullWidth variant="outlined" label='Pass marks' type="number" value={pmark} onChange={(event) => setPmark(event.target.value)} />
                </Stack>
                <Stack textAlign='right' spacing={1} direction='row' justifyContent='end' mt={2} >
                    <Button variant='outlined' onClick={() => navi(-1)} >Back</Button>
                    <Button variant="contained" sx={{width: '100%'}} onClick={() => upload()} disabled={uploading} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                </Stack>

                <Box mt={3} >
                    { marks.status === false ? <Alert severity="warning">{marks.msg}</Alert> :
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Full marks</TableCell>
                                    <TableCell align="left">Pass marks</TableCell>
                                    <TableCell align="right">Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { marks.map((data, i) =>
                                <TableRow key={i} >
                                    <TableCell component="th" scope="row">{i+1}</TableCell>
                                    <TableCell align="left">{data.marks_for}</TableCell>
                                    <TableCell align="left">{data.full_marks}</TableCell>
                                    <TableCell align="left">{data.pass_marks}</TableCell>
                                    <TableCell align="right"><IconButton onClick={() => marksDetail(data.marks_for)} ><Edit/></IconButton></TableCell>
                                </TableRow> ) }
                            </TableBody>
                        </Table>
                    </TableContainer> }
                </Box>
            </Container>
        </Box>
    );
}