import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

/* MUI */
import { Alert, Box, Card, CardContent, Container, Grid, IconButton, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Notifications, PendingActions, Person, Visibility, WorkspacePremium } from '@mui/icons-material'

export default function DashboardAdmin({apiglobal, apiadmin}){
    const navi = useNavigate()

    const [dash, setDash] = useState([])
    const [newstudents, setNewstudents] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard', '')
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === true && setDash(data.data)
            setNewstudents(data.newstudents)
        })
    }, [])

    const [franchises, setFranchises] = useState([])
    const [fLoading, setFloading] = useState(false)
    useEffect(() => {
        setFloading(true)
        var formData = new FormData()
        formData.append('get-franchises', '')
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setFloading(false)
            data.status == false ? setFranchises(data) : setFranchises(data.data)
        })
    }, [])

    return(
        <Box className='content' >
        <Container >
            <Grid container spacing={2} mt={3} >
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <Person sx={{fontSize: 70}} color='warning' />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >STUDENTS</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.students}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <Notifications sx={{fontSize: 70, color: '#007FFF'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >ACTIVE</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.active_students}</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box padding={2} component={Paper} textAlign='center' >
                        <WorkspacePremium sx={{fontSize: 70, color: '#32CD32'}} />
                        <Box sx={{padding: 0.1, background: 'red'}} />

                        <Stack mt={1} direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography color='gray' fontSize={12} letterSpacing={1} >ISSUED CERTIFICATE</Typography>
                            <Typography color='red' fontWeight='bold' fontSize={20} letterSpacing={3} >{dash.issued_certificate}</Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={6}>
                    <Box padding={2} component={Paper} height={350} overflow='auto' >
                        <Typography color='gray' fontSize={14} >7 days admission record</Typography>
                        { newstudents.status === false ? <Alert severity='warning' sx={{mt: 3}} >{newstudents.msg}</Alert> :
                        <TableContainer>
                            <Table>
                                <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Father</TableCell>
                                    <TableCell align="left">Gender</TableCell>
                                    <TableCell align="right">Join date</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {newstudents.map((row, i) => (
                                    <TableRow sx={{cursor: 'pointer'}}
                                    key={i} onClick={() => navi(`/controller/student-info/${row.studentid}`)} >
                                    <TableCell component="th" scope="row">
                                        {i+1}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.guardian}</TableCell>
                                    <TableCell align="left">{row.gender}</TableCell>
                                    <TableCell align="right">{row.joindate}</TableCell>
                                    <TableCell align="right" sx={{color: row.status === 'active' ? 'green' : 'orange', fontWeight: 'bold' }} >{row.status}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer> }
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box padding={2} component={Paper} textAlign='center' height={350} overflow='auto' >
                        { fLoading ? <LinearProgress color='inherit' /> :
                        franchises.status === false ? <Alert severity='warning'>{franchises.message}</Alert> :
                        <TableContainer>
                            <Table size='small' >
                                <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Franchise</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {franchises.map((row, i) => (
                                    <TableRow sx={{cursor: 'pointer'}}
                                    key={i} >
                                    <TableCell component="th" scope="row">
                                        {i+1}
                                    </TableCell>
                                    <TableCell align="left">{row.fr_name}</TableCell>
                                    <TableCell align="left" sx={{color: row.fr_status === 'active' ? 'green' : 'orange', fontWeight: 'bold'}} >{row.fr_status}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer> }
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
}