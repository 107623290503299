import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Alert, Avatar, Badge, Box, Button, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, IconButton, LinearProgress, Paper, Stack, TextField, Typography } from '@mui/material'
import { Add, CameraAlt, Delete, Edit } from '@mui/icons-material'
import { styled } from '@mui/material/styles'


export default function Franchises({snackbar, apiadmin, assetfranchise}){

    const navi = useNavigate()

    useEffect(() => {
        getFranchises()
    }, [])

    const [franchises, setFranchises] = useState([])
    const [fLoading, setFloading] = useState(false)
    const getFranchises = () => {
        setFloading(true)
        var formData = new FormData()
        formData.append('get-franchises', '')
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setFloading(false)
            data.status == false ? setFranchises(data) : setFranchises(data.data)
        })
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [uploading, setUploading] = useState(false)
    const updateLogo = (event, franchiseid) => {
        setUploading(true); snackbar('Uploading...')
        var formData = new FormData()
        formData.append('upload-franchise-logo', franchiseid)
        formData.append('franchise-logo', event.target.files[0])
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.status === false){
                snackbar(data.msg)
            } else {
                snackbar(data.msg); getFranchises()
            }
        })
    }

    const [opendelete, setOpendelete] = useState(false)
    const [typedelete, setTypedelete] = useState('')
    const [deletefranchise, setDeletefranchise] = useState('')
    const deleteDialog = (franchiseid) => {
        setDeletefranchise(franchiseid); setOpendelete(true)
    }

    const [deleting, setDeleting] = useState(false)
    const deleteFranchise = () => {
        setDeleting(true)
        var formData = new FormData()
        formData.append('delete-franchise', deletefranchise)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setDeleting(false)
            setUploading(false)
            if(data.status === false){
                snackbar(data.msg)
            } else {
                setOpendelete(false); getFranchises()
            }
        })
    }

    return(
        <Box className='content' mt={4}>
            <Container>
                <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} >
                    <Typography fontSize={14} fontWeight='bold' >Franchises</Typography>
                    <Box>
                        <Button startIcon={<Add/>} variant="outlined" onClick={() => navi('/controller/franchise-upload/0')} >Add franchise</Button>
                    </Box>
                </Stack>
                { fLoading ? <LinearProgress color="primary" /> : franchises.status == false ? <Alert severity="warning" >{franchises.message}</Alert> :
                <Grid container spacing={2} >
                    { franchises.map((data, i) =>
                    <Grid item xs={12} md={4} key={i} >
                        <Box padding={1.5} component={Paper} >
                            <Stack mb={3} direction='row' alignItems='center' spacing={2} >
                                <Box>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={ <IconButton component='label' ><CameraAlt /><VisuallyHiddenInput type="file" accept=".jpeg, .jpg, .png" disabled={uploading} onChange={(event) => updateLogo(event, data.franchiseid)} /></IconButton> } >
                                        <Avatar alt={data.fr_name} src={assetfranchise+data.franchiseid+'.jpg'} sx={{width: 120, height: 120, borderRadius: 5}} />
                                    </Badge>
                                </Box>
                                <Box>
                                    <Typography fontWeight='bold' fontSize={15} className="wrap-1" >{data.fr_name}</Typography>
                                    <Typography fontSize={14} color='gray' className="wrap-1" >{data.fr_address}, {data.fr_city}</Typography>
                                </Box>
                            </Stack>
                            <Box mb={2} >
                                <Typography noWrap fontWeight='bold' fontSize={14} >Owned by: {data.fr_owner_name}</Typography>
                                <Typography noWrap fontWeight='bold' fontSize={14} >Email: {data.fr_email}</Typography>
                                <Typography noWrap fontWeight='bold' fontSize={14} >Join date - {data.fr_joined}</Typography>
                            </Box><Divider/>
                            <Stack mt={1} direction='row' alignItems='center' justifyContent='space-between' >
                                <Box>
                                    <IconButton color="error" onClick={() => deleteDialog(data.franchiseid)} ><Delete/></IconButton>
                                    <IconButton color="primary" onClick={() => navi(`/controller/franchise-upload/${data.franchiseid}`)} ><Edit/></IconButton>
                                </Box>
                                {data.fr_status == 'active' ? <Typography color='green' fontSize={14} fontWeight='bold' >Active</Typography> : <Typography color='orange' fontSize={14} fontWeight='bold' >Pending</Typography> }
                            </Stack>
                        </Box>
                    </Grid> )}
                </Grid> }
            </Container>
            <Dialog open={opendelete} fullWidth maxWidth='xs' onClose={() => setOpendelete(false)} >
                <DialogContent>
                    <Box pb={3} >
                        <Typography fontSize={30} color='orange' fontWeight='bold' >Do you want to delete this franchise?</Typography>
                        <Typography fontSize={12} color='darkred' mb={2} >Note: All data releted to this franchise will be deleted. Like student, certificate etc...</Typography>
                        
                        <Typography fontSize={14} color='gray' mb={0.5} >To delete this franchise type <i>'delete'</i> below</Typography>
                        <TextField fullWidth variant="outlined" placeholder="delete" value={typedelete} onChange={(event) => setTypedelete(event.target.value)} />

                        <Box mt={2} textAlign='center' >
                            <Button variant="contained" color="error" disabled={deleting ? true : typedelete === 'delete' ? false : true} onClick={() => deleteFranchise()} >{deleting ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );

}