import React, { useEffect } from "react";
import {  } from 'react-router-dom'

// MUI
import { Box, Card, CardActions, CardContent, Container, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { Mail, RingVolume, School, WhatsApp } from '@mui/icons-material'

import Footer from "./footer";

export default function Contact(){

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return(
        <><Container>
            <Grid container>
                <Grid item xs={12} md={9} mx='auto' mt={5} >
                    <Box textAlign='center' >
                        <Typography fontSize={{xs: 30, md: 45}} fontWeight='bold' letterSpacing={1} color='orange' >Get in touch with us for</Typography>
                        <Typography fontSize={{xs: 30, md: 45}} fontWeight='bold' letterSpacing={1} color='primary.main' >More Information</Typography>
                        <Typography fontSize={14} color='gray' >If you need help or have a question, we're here for you</Typography>
                    </Box>

                    <Stack spacing={2} direction={{xs: 'column', md: 'row'}} mt={4} >
                        <Card elevation={0} variant="outlined" sx={{width: '100%'}} >
                            <CardContent>
                                <School sx={{fontSize: 70, color: 'primary.main'}} />
                                <Typography fontSize={30} fontWeight='bold' letterSpacing={2} color='darkblue' >VIIT HEAD OFFICE</Typography>
                                <Typography fontSize={16} >Owner: Vivek sir</Typography>
                                <Typography fontSize={16} >Institute code: KTR</Typography>
                                <Typography fontSize={16} >Address: Rishi Bhawan Road, Bara Bazar, Katihar, PIN code - 854015</Typography>
                                <Typography fontSize={16} >Bihar, India</Typography>
                            </CardContent><Divider/>
                            <CardActions disableSpacing>
                                <Typography fontSize={14} color='gray' mr={2} >Contact</Typography>
                                <IconButton color="primary" onClick={() => window.location.href = 'https://wa.me/+919534834324'} ><WhatsApp /></IconButton>
                                <IconButton color="primary" onClick={() => window.location.href = 'tel: 9534834324'} ><RingVolume /></IconButton>
                                <IconButton color="primary" onClick={() => window.location.href = 'mailto: viitcomputer2013@gmail.com'} ><Mail /></IconButton>
                            </CardActions>
                        </Card>
                        <Card elevation={0} variant="outlined" sx={{width: '100%'}} >
                            <CardContent>
                                <School sx={{fontSize: 70, color: 'primary.main'}} />
                                <Typography fontSize={30} fontWeight='bold' letterSpacing={2} color='darkblue' >VIIT OFFICE</Typography>
                                <Typography fontSize={16} >Owner: Manjit sir</Typography>
                                <Typography fontSize={16} >Institute code: DSC</Typography>
                                <Typography fontSize={16} >Address: 2nd Gate of D.S. College (Oppo.) (Near Yamaha Showroom), Katihar, PIN code - 854105</Typography>
                                <Typography fontSize={16} >Bihar, India</Typography>
                            </CardContent><Divider/>
                            <CardActions disableSpacing>
                                <Typography fontSize={14} color='gray' mr={2} >Contact</Typography>
                                <IconButton color="primary" onClick={() => window.location.href = 'https://wa.me/+918271090663'} ><WhatsApp /></IconButton>
                                <IconButton color="primary" onClick={() => window.location.href = 'tel: 8271090663'} ><RingVolume /></IconButton>
                                <IconButton color="primary" onClick={() => window.location.href = 'mailto: manjitktr@gmail.com'} ><Mail /></IconButton>
                            </CardActions>
                        </Card>
                    </Stack>
                </Grid>
            </Grid>
        </Container><Footer/></>
    );
}