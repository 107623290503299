import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Alert, Box, Button, ButtonGroup, Card, CardContent, CircularProgress, Container, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function StudentMarks({apiglobal, snackbar}){
    const { studentid } = useParams()
    const { courseid } = useParams()

    useEffect(() => {
        courseDetail()
        getMarks()
    }, [])

    const [course, setCourse] = useState([])
    const courseDetail = () => {
        var formData = new FormData()
        formData.append('get-course-detail', courseid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === false ? setCourse(data) : setCourse(data.data)
        })
    }
    const [marks, setMarks] = useState([])
    const [speed, setSpeed] = useState([])
    const getMarks = () => {
        var formData = new FormData()
        formData.append('get-subject-marks', studentid); formData.append('courseid', courseid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setSpeed(data.typing)
            data.status === false ? setMarks(data) : setMarks(data.data)
        })
    }

    const upload_marks = (event, markstype) => {
        if(event.keyCode === 13){
            var formData = new FormData()
            formData.append('add-subject-obtained-marks', studentid); formData.append('courseid', courseid); formData.append('markstype', markstype); formData.append('marks', event.target.value)
            fetch(apiglobal, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                snackbar(data.message);
                data.status === true && getMarks()
            })
        }
    }

    return(
        <Box className='content' >
            <Container><Back text='Student info' />
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Card sx={{height: '100%'}} >
                            <CardContent>
                                { course.status === false ? <Alert severity="warning">{course.message}</Alert> :
                                <Box mt={3} mb={3} >
                                    <Typography fontSize={35} fontWeight='bold' color='orange' textAlign='center' noWrap >{course.c_name}</Typography>
                                    <Typography fontSize={14} color='gray' textAlign='center' noWrap >Duration - {course.c_duration} months</Typography>
                                    <Typography fontSize={14} color='gray' textAlign='center' noWrap >{course.c_title}</Typography>
                                </Box> }
                                <Box>
                                    { course.c_title === 'TYPING' ? null : 
                                    <ButtonGroup sx={{width: '100%', mb: 1}} >
                                        <Button sx={{width: '100%'}} color="info" >Full marks: {marks.reduce((total, i) => total + Number(i.full_marks),0)}</Button>
                                        <Button sx={{width: '100%'}} color="warning" >Pass marks: {marks.reduce((total, i) => total + Number(i.pass_marks),0)}</Button>
                                        <Button sx={{width: '100%'}} color="success" >Obtained mark: {marks.reduce((total, i) => total + Number(i.obtained_marks),0)}</Button>
                                    </ButtonGroup>}
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{height: '100%'}} >
                            { course.c_title === 'TYPING' ?
                            <CardContent>
                                <Typography fontSize={14} color='gray' mb={1} >Student typing speed</Typography>
                                <TextField fullWidth variant="outlined" helperText='Example: HIN - 35 W.P.M. & ENG - 40 W.P.M.' placeholder={speed.status === false ? speed.message : speed.speed} onKeyUp={(event) => upload_marks(event, 'TYPING')} />
                            </CardContent>
                            :
                            <CardContent>
                                    <Typography fontSize={14} color='gray' >Student obtained marks</Typography>
                                    { marks.map((row, i) =>
                                    <Stack mt={1} key={i} >
                                        <Typography color='gray' fontSize={14} >{row.marks_for} - {row.full_marks}</Typography>
                                        <TextField fullWidth variant="outlined" placeholder={row.obtained_marks} onKeyUp={(event) => upload_marks(event, row.marks_for)} />
                                    </Stack> ) }
                            </CardContent>}
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}