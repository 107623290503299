import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import Back from "../bar";

// MUI
import { Alert, Avatar, Backdrop, Badge, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Menu, MenuItem, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { Add, CameraAlt, ExpandMore } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import axios from "axios";

export default function StudentInfo({apiadmin, apiglobal, snackbar, auth, assetstudent, fullscreen}){
    const { studentid } = useParams()
    let navi = useNavigate()
    const [action, setAction] = useState('')

    useEffect(() => {
        studentDetails()
        assignedCourses()
        getCourses()
    }, [])
    const [student, setStudent] = useState([])
    const studentDetails = () => {
        var formData = new FormData()
        formData.append('get-student-detail', studentid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === false ? snackbar(data.message) : setStudent(data.data)
        })
    }

    const [acourse, setAcourse] = useState([])
    const assignedCourses  = () => {
        var formData = new FormData()
        formData.append('assigned-courses', studentid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === false ? setAcourse(data) : setAcourse(data.data)
        })
    }

    const [courses, setCourses] = useState([])
    const [opencoursedialog, setOpencoursedialog] = useState(false)
    const getCourses  = () => {
        var formData = new FormData()
        formData.append('get-courses', '')
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === false ? setCourses(data) : setCourses(data.data)
        })
    }

    const [working, setWorking] = useState(false)
    const assignCourse = (courseid) => {
        setOpencoursedialog(false)
        setWorking(true)
        var formData = new FormData()
        formData.append('assign-course', courseid)
        formData.append('studentid', studentid)
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.status === false){
                snackbar(data.message)
            } else {
                snackbar(data.message)
                assignedCourses()
            }
        })
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [courseid, setCourseid] = useState('')
    const [ctitle, setCtitle] = useState('')
    const openmenu = (event, courseid, ctitle) => {
        setCtitle(ctitle)
        setCourseid(courseid)
        setAnchorEl(event.currentTarget);
    };

    const [issuedialog, setIssuedialog] = useState(false)
    const [enrollment, setEnrollment] = useState('')
    const [certificate, setCertificate] = useState('')
    const [examdate, setExamdate] = useState('')
    const [issuedate, setIssuedate] = useState('')
    const [issuestatus, setIssuestatus] = useState('')
    const getCertificateDetail = () => {
        setIssuedialog(true)
        var formData = new FormData()
        formData.append('get-certificate-detail', studentid)
        formData.append('courseid', courseid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.status !== false){
                setEnrollment(data.data.enrollment_no); setCertificate(data.data.certificate_no); setExamdate(data.data.exam_date); setIssuedate(data.data.issued_on); setIssuestatus(data.data.status)
            }
        })
    }
    const issueCertificate = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('issue-certificate', studentid)
        formData.append('courseid', courseid); formData.append('enrollment', enrollment); formData.append('certificate', certificate); formData.append('examdate', examdate); formData.append('issuedate', issuedate)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.status === false){
                snackbar(data.message)
            } else {
                setIssuedialog(false)
                snackbar(data.message)
            }
        })
    }

    const deleteCertificate = () => {
        setIssuedialog(false)
        setWorking(true)
        var formData = new FormData()
        formData.append('delete-issued-certificate', studentid)
        formData.append('courseid', courseid)
        fetch(apiadmin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.status === false){
                snackbar(data.message)
            } else {
                snackbar(data.message);
            }
        })
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [uploading, setUploading] = useState(false)
    const updateLogo = (event) => {
        setUploading(true); snackbar('Uploading...')
        var formData = new FormData()
        formData.append('add-student-photo', studentid)
        formData.append('image', event.target.files[0])
        fetch(apiglobal, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.status === false){
                snackbar(data.msg)
            } else {
                snackbar(data.msg); window.location.reload()
            }
        })
    }

    const [opendelete, setOpendelete] = useState(false)
    const deleteAssignedCourse = () => {
        setAction('deletingcourse')
        var formData = new FormData()
        formData.append('remove-assigned-course', studentid)
        formData.append('courseid', courseid)
        axios.post(apiadmin, formData)
        .then(function(res){
            setAction('')
            if(res.data.status === false){
                snackbar(res.data.msg)
            } else {
                snackbar(res.data.msg); setOpendelete(false); assignedCourses()
            }
        })
    }

    return(
        <Box className='content' >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={working}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container><Back text='Student info' />
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6}>
                        <Card sx={{height: '100%'}} >
                            <CardContent>
                                <Box textAlign='center' mb={2} >
                                    <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={ <IconButton component='label' ><CameraAlt /><VisuallyHiddenInput type="file" accept=".jpeg, .jpg, .png" disabled={uploading} onChange={(event) => updateLogo(event)} /></IconButton> } >
                                        <Avatar alt={student.name} src={assetstudent+studentid+'.jpg'} sx={{width: 120, height: 120}} />
                                    </Badge>
                                    <Typography mt={1} fontSize={25} fontWeight='bold' >{student.name}</Typography>
                                </Box><Divider/>
                                <Toolbar>
                                    <Typography fontSize={14} color='gray' flexGrow={1} >Join date</Typography>
                                    <Typography fontSize={14} color='green' fontWeight='bold' >{new Date(student.joindate).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: 'numeric'})}</Typography>
                                </Toolbar><Divider/>
                                <Toolbar>
                                    <Typography fontSize={14} color='gray' flexGrow={1} >Status</Typography>
                                    <Typography fontSize={14} color={student.status === 'active' ? 'green' : student.status === 'pending' ? 'orange' : '#007FFF'} fontWeight='bold' >{student.status}</Typography>
                                </Toolbar>
                            </CardContent>
                        </Card>
                        
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{height: '100%'}} >
                            <CardContent>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2} >
                                    <Typography fontSize={14} color='gray' >Assign course</Typography>
                                    <IconButton sx={{bgcolor: '#F5F5F5'}} onClick={() => setOpencoursedialog(true)} ><Add /></IconButton>
                                </Stack><Divider/>
                                { acourse.status === false ? <Alert severity="warning" sx={{mt: 1}} >{acourse.message}</Alert> :
                                acourse.map((data, i) =>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' mt={2} key={i} >
                                    <Box>
                                        <Typography fontSize={18} fontWeight='bold' >{data.c_title}</Typography>
                                        <Typography fontSize={14} color='gray' >{data.c_name}</Typography>
                                    </Box>
                                    <Stack direction='row'  >
                                        <IconButton sx={{bgcolor: '#F5F5F5', ml: 0.5}} onClick={(event) => openmenu(event, data.courseid, data.c_title)} ><ExpandMore /></IconButton>
                                    </Stack>
                                </Stack>) }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={()=> setAnchorEl(null)}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }} >
                <MenuItem onClick={() => navi(`/controller/student-marks/${studentid}/${courseid}`)}>{ctitle === 'TYPING' ? 'Speed' : 'Marks'}</MenuItem>
                {ctitle === 'TYPING' ? <MenuItem onClick={() => navi(`/public/typing-certificate/${studentid}/${courseid}`)} >Certificate</MenuItem> :
                <MenuItem onClick={() => navi(`/public/computer-certificate/${studentid}/${courseid}`)} >Certificate</MenuItem> }
                {ctitle !== 'TYPING' ? <MenuItem onClick={() => navi(`/public/computer-marksheet/${studentid}/${courseid}`)} >Marksheet</MenuItem> : null }
                {auth === 'admin' ? <MenuItem onClick={() => getCertificateDetail()} >Issue</MenuItem> : null }
                <MenuItem onClick={() => navi(`/admission-form/${studentid}/${courseid}`)} >Form</MenuItem>
                <MenuItem onClick={() => setOpendelete(true)} >Delete</MenuItem>
            </Menu>
            <Dialog open={opencoursedialog} fullScreen={fullscreen} fullWidth maxWidth='xs' onClose={() => setOpencoursedialog(false)} >
                <DialogTitle>Active courses</DialogTitle><Divider/>
                <DialogContent>
                    <Typography fontSize={14} color='gray' >Click to assign course</Typography>
                    { courses.status === false ? <Alert severity="warning">{courses.message}</Alert> :
                    courses.map((data, i) =>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' mt={0.5} key={i} sx={{border: 'thin solid #CCCCCC', borderRadius: 1.5, padding: 1, cursor: 'pointer'}} onClick={() => assignCourse(data.courseid)} >
                            <Box>
                                <Typography fontSize={18} fontWeight='bold' >{data.c_title}</Typography>
                                <Typography fontSize={14} color='gray' >Duration: {data.c_duration}</Typography>
                                <Typography fontSize={14} color='gray' >{data.c_name}</Typography>
                            </Box>
                        </Stack>) }
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" color='inherit' onClick={() => setOpencoursedialog(false)} >Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={issuedialog} fullScreen={fullscreen} fullWidth maxWidth='xs' onClose={() => setIssuedialog(false)} >
                <DialogTitle>Issue certificate</DialogTitle><Divider/>
                <DialogContent>
                    <Stack spacing={1} >
                        <TextField variant="outlined" label='Registration/Enrollment number' type="text" value={enrollment} onChange={(event) => setEnrollment(event.target.value)} />
                        <TextField variant="outlined" label='Certificate number' type="text" value={certificate} onChange={(event) => setCertificate(event.target.value)} />
                        <TextField variant="outlined" helperText='Exam date' type="date" value={examdate} onChange={(event) => setExamdate(event.target.value)} />
                        <TextField variant="outlined" helperText='Issue date' type="date" value={issuedate} onChange={(event) => setIssuedate(event.target.value)} />
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" color='inherit' onClick={() => setIssuedialog(false)} >Close</Button>
                    <Button variant="outlined" color="error" disabled={issuestatus === 'issued' ? false : true} onDoubleClick={() => deleteCertificate()} >Delete</Button>
                    <Button variant="contained" color="primary" onClick={() => issueCertificate()} disabled={issuestatus === 'issued' ? true : false} >Issue</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={opendelete} fullWidth maxWidth='xs' onClose={() => setOpendelete(false)} >
                <DialogContent>
                <Typography fontSize={30} color='orange' fontWeight='bold' >Do you want to delete this assigned course?</Typography>
                    <Typography fontSize={12} color='darkred' mb={2} >Note: All data releted to this course will be deleted. Like marks, certificate etc...</Typography>
                        
                    <Typography fontSize={14} color='gray' mb={0.5} >To delete this assigned course type <i>'delete'</i> below</Typography>
                    <TextField fullWidth variant="outlined" placeholder="delete" value={action} onChange={(event) => setAction(event.target.value)} />
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpendelete(false)} >Close</Button>
                    <Button variant="contained" color="error" disabled={action === 'delete' ? false : true} onClick={() => deleteAssignedCourse()} >{action === 'deletingcourse' ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}