import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import PublicHeader from './header'

// MUI
import { Box, Container, Grid, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function TypingCertificate({apipublic}){
    const { studentid } = useParams()
    const { courseid } = useParams()
    const area = useRef()

    const [info, setInfo] = useState([])
    const [speed, setSpeed] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('get-issued-certificate', studentid)
        formData.append('courseid', courseid)
        fetch(apipublic, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            data.status === true ? setInfo(data.data) : setInfo(data); setSpeed(data.typing)
        })
    }, [])

    return(
        <><PublicHeader text='Certificate' printarea={area} isprint={true} />
        { info.status === false ? <Typography fontSize={40} color='red' textAlign='center' fontWeight={'bold'} mt={10} >Certificate not issued yet!</Typography>
        :
        <Container>
            <Grid container mt={10} >
                <Grid item xs={12} md={12} mx='auto' overflow='scroll' >
                    <Box ref={area} sx={{position: 'relative', width: '295mm', height: '208mm', mx:'auto'}} >
                        <img src="/images/typing_certificate.webp" alt="Computer certificate" style={{width: '296mm', height: '209mm'}} />

                        <Typography sx={{ position: 'absolute', top: '13mm', left: '247mm' }} fontSize={12} fontWeight='bold' fontFamily='monospace' color='darkred' >{info.certificate_no}</Typography>
                        <Typography sx={{ position: 'absolute', top: '75mm', left: '129mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.name}</Typography>
                        <Typography sx={{ position: 'absolute', top: '84mm', left: '129mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.guardian}</Typography>
                        <Typography sx={{ position: 'absolute', top: '93.5mm', left: '129mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.enrollment_no}</Typography>
                        <Typography sx={{ position: 'absolute', top: '103.5mm', left: '152mm', textTransform: 'uppercase' }} fontSize={16} fontWeight='bold' >{info.c_name} ({info.c_title})</Typography>
                        <Typography sx={{ position: 'absolute', top: '112.5mm', left: '129mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{info.c_duration} Months</Typography>
                        <Typography sx={{ position: 'absolute', top: '121.5mm', left: '129mm', textTransform: 'uppercase' }} fontSize={18} fontWeight='bold' >{speed.obtained_marks}</Typography>
                        <Typography sx={{ position: 'absolute', top: '140.5mm', left: '129mm' }} fontSize={18} fontWeight='bold' >{info.fr_name}</Typography>
                        <Typography sx={{position: 'absolute', top: '162mm', left: '45mm'}} fontSize={18} fontWeight='bold' >{info.issued_on}</Typography>

                    </Box>
                </Grid>
            </Grid>
        </Container> }</>
    );
}